import { Breadcrumb } from 'antd';
type Props = {
  subcategory?: {
    name: string;
    url: string;
  };
  category: {
    active: boolean;
    name: string;
  };
};

const BreadcrumbHead = (props: Props): JSX.Element => {
  return (
    <Breadcrumb className="p-4 bg-white">
      <Breadcrumb.Item>Home</Breadcrumb.Item>
      {props.subcategory && (
        <Breadcrumb.Item>
          <a href={props.subcategory.url}>{props.subcategory?.name}</a>
        </Breadcrumb.Item>
      )}
      <Breadcrumb.Item>{props.category.name}</Breadcrumb.Item>
    </Breadcrumb>
  );
};
export default BreadcrumbHead;
