/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '@aws-amplify/storage/node_modules/axios';
import { AxiosResponse } from 'axios';
import { IPagingResponse, IResponse } from 'interface/base.interface';
import { IGetPlantQuery, IPlant } from 'interface/plant.interface';
import axiosClient from './axiosClient';

const GOONG_API_ENDPOINT =
  process.env.REACT_APP_GOONG_API_ENDPOINT || 'https://rsapi.goong.io';
const GOONG_API_KEY = process.env.REACT_APP_GOONG_API_KEY;

const PlantAPI = {
  getPlants: async (
    params: IGetPlantQuery
  ): Promise<IPagingResponse<IPlant>> => {
    return await axiosClient.get('/abe/plant', { params });
  },

  getPlant: async (code: string): Promise<IPlant> => {
    return await axiosClient.get(`/abe/plant/${code}`);
  },

  updatePlant: async (payload: {
    code: string;
    longitude: number;
    latitude: number;
  }): Promise<IResponse> => {
    return await axiosClient.put('/abe/plant', payload);
  },

  getPlaces: async (params: {
    location: string;
    input: string;
  }): Promise<AxiosResponse<any>> => {
    return await axios.get(`${GOONG_API_ENDPOINT}/Place/AutoComplete`, {
      params: { api_key: GOONG_API_KEY, ...params },
    });
  },

  getPlace: async (place_id: string): Promise<AxiosResponse<any>> => {
    return await axios.get(`${GOONG_API_ENDPOINT}/Place/Detail`, {
      params: { api_key: GOONG_API_KEY, place_id },
    });
  },
};

export default PlantAPI;
