import { Form, Table, Tag } from 'antd';
import Button from 'components/Button';
import MainLayout from 'components/Modules/Layout/MainLayout';
import useQueryParam from 'hooks/useQueryPrams';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { HocChangePagination } from 'utils/HocChangePagination';
import { openNotificationWithIcon } from 'utils/notification';
import RatingApi from 'apis/RatingApi';
import moment from 'moment';
import RatingFilter from 'components/Modules/rating/RatingFilter';
import queryString from 'query-string';
import { calculateColumnsWidth } from 'utils/calculateWidthColumn';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
  translateRatingGiStatusPHHH,
  translateRatingStatus,
  translateRatingStatusAction,
} from 'utils/translateStatus';
import RatingUpdateGI from 'components/Modules/rating/RatingUpdateGI';
import {
  IGetRatingQuery,
  ImportRatingQuery,
  getStoDtoGiPhhhQuery,
} from 'interface/rating.interface';
import RatingDetail from 'components/Modules/rating/RatingDetail';
import handleError401 from 'utils/handleError401';
import { read, utils } from 'xlsx';
import * as XLSX from 'xlsx';
export default function RatingListPage(): JSX.Element {
  const history = useHistory();

  const queryParam = useQueryParam();

  const page = parseInt(queryParam.get('page') + '') || 1;

  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const gi_id = queryParam.get('gi_id') || undefined;
  const gr_id = queryParam.get('gr_id') || undefined;
  const plant_name = queryParam.get('receiving_plant_name') || undefined;
  const status = queryParam.get('status') || undefined;
  const gi_status_phhh = queryParam.get('gi_status_phhh') || undefined;
  const toDate = queryParam.get('toDate') || undefined;
  const fromDate = queryParam.get('fromDate') || undefined;

  const ratingParams = {
    page,
    page_size,
    gi_id,
    gr_id,
    plant_name,
    status,
    gi_status_phhh,
    fromDate,
    toDate,
  };

  const [rating, setRating] = useState<any>();
  const params = JSON.parse(JSON.stringify(ratingParams));
  const [currentRatingDt, setCurrentRatingDt] = useState<string>('');
  const [isRatingDetail, setIsRatingDetail] = useState<boolean>(false);
  const [currentUpdateRating, setCurrentUpdateRating] =
    useState<IGetRatingQuery>();
  const [isUpdateRating, setIsUpdateRating] = useState<boolean>(false);
  const [ratingList, setRatingList] = useState<any>();

  const { data: RatingData } = useQuery(
    ['getRatingList', ratingParams],
    () => RatingApi.getRatingList(params),
    {
      onSuccess: (data) => {
        const listProduct = [];
        for (let i = 0; i < data.data.length; i++) {
          let listEmail = '';
          if (data.data[i].email_rating.length > 0) {
            for (let j = 0; j < data.data[i].email_rating.length; j++) {
              if (data.data[i].email_rating[j] != null) {
                if (listEmail != '') {
                  listEmail = listEmail + ', ';
                }
                listEmail = listEmail + data.data[i].email_rating[j];
              }
            }
          }
          listProduct.push({
            id: data.data[i].id,
            gi_id: data.data[i].gi_id,
            gr_id: data.data[i].gr_id,
            star_rating: data.data[i].star_rating,
            service_rating: data.data[i].service_rating,
            email_rating: listEmail,
            status: data.data[i].status,
            note: data.data[i].note,
            receiving_plant: data.data[i].receiving_plant,
            receiving_plant_name: data.data[i].receiving_plant_name,
            deliveryUnit: data.data[i].deliveryUnit,
            sto_phhh: data.data[i].sto_phhh,
            do_phhh: data.data[i].do_phhh,
            gi_phhh: data.data[i].gi_phhh,
            gi_status_phhh: data.data[i].gi_status_phhh,
            status_user_note: data.data[i].status_user_note,
            user_note: data.data[i].user_note,
            syn_date: data.data[i].syn_date,
            syn_sap_status: data.data[i].syn_sap_status,
            rewaiting_date: data.data[i].rewaiting_date,
            rating_date: data.data[i].rating_date,
            rating_by: data.data[i].rating_by,
          });
        }
        setRatingList(listProduct);
      },
    }
  );

  const { data: RatingDt } = useQuery(
    ['getRatingDtByRating', currentRatingDt],
    () => RatingApi.getRatingDtByRating(currentRatingDt),
    { enabled: !!currentRatingDt }
  );

  const { mutate: uploadUserStatusRating, isLoading: uploadFileLoading } =
    useMutation(RatingApi.postImportRating, {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Upload file thành công');
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', 'Upload file thất bại');
        if (error.response.data.status === 401) {
          handleError401();
        }
      },
    });

  const columns: any = [
    {
      title: 'Mã GI',
      dataIndex: 'gi_id',
      fixed: 'left',
    },
    {
      title: 'Mã GR',
      dataIndex: 'gr_id',
      fixed: 'left',
    },
    {
      title: 'Mã cửa hàng',
      dataIndex: 'receiving_plant',
    },
    {
      title: 'Tên cửa hàng',
      dataIndex: 'receiving_plant_name',
    },
    {
      title: 'Ngày nhận hàng',
      dataIndex: 'receive_at',
      render: (_: any, record: any) => (
        <span>{moment(record.receive_at)?.format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    {
      title: 'Ngày phản hồi',
      dataIndex: 'rating_date',
      render: (_: any, record: any) => (
        <span>{moment(record.rating_date)?.format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
    {
      title: 'Email gửi đánh giá',
      dataIndex: 'email_rating',
    },
    {
      title: 'Đánh giá GI',
      dataIndex: 'star_rating',
    },
    {
      title: 'Trạng thái đánh giá',
      dataIndex: 'status',
      render: (_: any, record: any) => (
        <span>{translateRatingStatus(record.status)}</span>
      ),
    },
    {
      title: 'Trạng thái gửi phản hồi',
      dataIndex: 'gi_status_phhh',
      render: (_: any, record: any) => (
        <span>{translateRatingGiStatusPHHH(record.gi_status_phhh)}</span>
      ),
    },
    {
      title: 'STO PHHH',
      dataIndex: 'sto_phhh',
    },
    {
      title: 'DO PHHH',
      dataIndex: 'do_phhh',
    },
    {
      title: 'GI PHHH',
      dataIndex: 'gi_phhh',
    },
    {
      title: 'GI Status PHHH',
      dataIndex: 'gi_status_phhh',
    },
    {
      title: 'GI Status Xử Lý',
      dataIndex: 'status_user_note',
    },
    {
      title: 'Note',
      dataIndex: 'user_note',
    },
    {
      title: 'Thao tác',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      render: (_: any, record: any) => (
        <div className="flex justify-center">
          <Tag
            onClick={() => handleShowGIDetailModal(record)}
            className="outline-none"
            color="blue"
          >
            {translateRatingStatusAction(record.status)}
          </Tag>
        </div>
      ),
    },
  ];

  const handleShowGIDetailModal = (record: any) => {
    if (record?.status === 'done') {
      setIsRatingDetail(true);
      setCurrentRatingDt(record?.id);
    } else if (record?.status === 'expried') {
      setIsUpdateRating(true);
      setCurrentUpdateRating(record);
    }
  };

  const onSelectChange = (selectedRows: any) => {
    setRating(selectedRows);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  const handleSearch = (values: any) => {
    const search = queryString.stringify(values);

    history.push({
      pathname: '/rating',
      search: `${search}&page=1`,
    });
  };

  const handleImport = (event: any) => {
    const files = event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target?.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(
            wb.Sheets[sheets[0]]
          ) as unknown as ImportRatingQuery;
          uploadUserStatusRating(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const { mutate: getStoDtoGiPhhh } = useMutation(RatingApi.getStoDtoGiPhhh, {
    onSuccess: (data) => {
      if (data.status === 'false') {
        openNotificationWithIcon('error', 'Cập nhật thất bại');
      } else {
        openNotificationWithIcon('success', 'Cập nhật thành công');
      }
    },
    onError: (error) => {
      console.log(error);
      openNotificationWithIcon('error', 'Cập nhật thất bại');
    },
  });

  const handleGetSto = async () => {
    if (!rating) return;
    const type = 'STO';
    for (let i = 0; i < rating.length; i++) {
      if (
        rating[i].sto_phhh === null &&
        rating[i].status === 'done' &&
        rating[i].star_rating < 5
      ) {
        const param = {
          type,
          gi_id: rating[i].gi_id,
        } as unknown as getStoDtoGiPhhhQuery;
        getStoDtoGiPhhh(param);
      } else {
        openNotificationWithIcon(
          'error',
          'GI: ' +
            rating[i].gi_id +
            ' phải chưa có mã STO,' +
            ' trạng thái phải là đã đánh giá' +
            ' và số rating phải nhỏ hơn 5'
        );
        return;
      }
    }
  };

  const handleGetDto = async () => {
    if (!rating) return;
    const type = 'DO';
    for (let i = 0; i < rating.length; i++) {
      if (rating[i].sto_phhh !== null && rating[i].do_phhh === null) {
        const param = {
          type,
          gi_id: rating[i].gi_id,
        } as unknown as getStoDtoGiPhhhQuery;
        getStoDtoGiPhhh(param);
      } else {
        openNotificationWithIcon(
          'error',
          'GI: ' + rating[i].gi_id + ' phải chưa có mã STO và chưa có mã DTO'
        );
        return;
      }
    }
  };

  const handleGetGI = async () => {
    if (!rating) return;
    const type = 'GI';
    for (let i = 0; i < rating.length; i++) {
      if (rating[i].sto_phhh !== null && rating[i].do_phhh !== null) {
        const param = {
          type,
          gi_id: rating[i].gi_id,
        } as unknown as getStoDtoGiPhhhQuery;
        getStoDtoGiPhhh(param);
      } else {
        openNotificationWithIcon(
          'error',
          'GI: ' + rating[i].gi_id + ' phải có mã STO và mã DTO'
        );
        return;
      }
    }
  };

  const { mutate: rejectRating } = useMutation(RatingApi.putRejectRating, {
    onSuccess: () => {
      openNotificationWithIcon('success', 'Cập nhật thành công');
    },
    onError: (error) => {
      console.log(error);
      openNotificationWithIcon('error', 'Cập nhật thất bại');
    },
  });

  const handleRejectRating = async () => {
    if (!rating) return;
    for (let i = 0; i < rating.length; i++) {
      if (rating[i].status !== 'done') {
        openNotificationWithIcon(
          'error',
          'GI: ' + rating[i].gi_id + ' chưa đánh giá xong.'
        );
        return;
      }
    }
    for (let i = 0; i < rating.length; i++) {
      const id = rating[i].id;
      rejectRating(id);
    }
  };

  const { mutate: reProcessRating } = useMutation(
    RatingApi.putReprocessRating,
    {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Cập nhật thành công');
      },
      onError: (error) => {
        console.log(error);
        openNotificationWithIcon('error', 'Cập nhật thất bại');
      },
    }
  );

  const handleReprocessRating = async () => {
    if (!rating) return;
    for (let i = 0; i < rating.length; i++) {
      if (rating[i].gi_status_phhh !== 'reject') {
        openNotificationWithIcon(
          'error',
          'GI: ' + rating[i].gi_id + 'phải là từ chối phản hồi'
        );
        return;
      }
    }
    for (let i = 0; i < rating.length; i++) {
      const id = rating[i].id;
      reProcessRating(id);
    }
  };

  const maxWidthPerCell = 600;
  const tableHeight = 500;
  const rowKey = 'id';
  const dataSource = [] as any;

  const dataTable = calculateColumnsWidth(
    columns,
    dataSource as [],
    maxWidthPerCell
  );

  function exportExcel(data: any, nameSheet: any, nameFile: any) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, nameSheet);
    XLSX.writeFile(wb, `${nameFile}.xlsx`);
  }

  return (
    <MainLayout>
      <RatingFilter handleSearch={handleSearch} />
      <Form layout="inline" className="items-center" autoComplete="off">
        <Form.Item>
          <Button onClick={handleGetSto}>Lấy Mã STO</Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={handleGetDto}>Lấy Mã DO</Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={handleGetGI}>Chấp nhận phản hồi</Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={handleRejectRating}>Từ chối phản hồi</Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={handleReprocessRating}>Đánh giá lại</Button>
        </Form.Item>
      </Form>
      <div className="flex flex-row-reverse">
        <label htmlFor="export-file">
          <Button
            onClick={() => exportExcel(ratingList, 'PHHH', 'Danh sách PHHH')}
          >
            <DownloadOutlined />
            Export
          </Button>
        </label>
        <label
          htmlFor="upload-file"
          className="bg-primary-50 opacity-100 rounded-2.5xl text-white para-bold-1 py-2 px-6 drop-shadow-button border-2"
        >
          <UploadOutlined />

          <span className="ml-2 para-2">Import</span>
        </label>

        <input
          type="file"
          onChange={handleImport}
          accept=".xlsx,.xls"
          id="upload-file"
          className="hidden"
        />
      </div>

      <div className="main-content">
        <Table
          rowKey={(record) => record}
          scroll={{ x: dataTable.tableWidth, y: tableHeight }}
          columns={columns}
          dataSource={ratingList}
          className="customTable"
          size="small"
          rowSelection={rowSelection}
          pagination={{
            current: page,
            pageSize: page_size,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50'],
            onChange: HocChangePagination(history),
          }}
        />

        {currentUpdateRating && (
          <RatingUpdateGI
            rating={currentUpdateRating}
            isVisible={isUpdateRating}
            onCancel={() => setIsUpdateRating(false)}
          />
        )}
        {RatingDt && (
          <RatingDetail
            rating={RatingDt}
            isVisible={isRatingDetail}
            onCancel={() => setIsRatingDetail(false)}
          />
        )}
      </div>
    </MainLayout>
  );
}
