/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Form, Input, Table, Select } from 'antd';
import MasterCodeApi from 'apis/MasterCodeApi';
import MainLayout from 'components/Modules/Layout/MainLayout';
import useQueryParam from 'hooks/useQueryPrams';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { calculateColumnsWidth } from 'utils/calculateWidthColumn';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { HocChangePagination } from 'utils/HocChangePagination';
import { read, utils } from 'xlsx';
import MasterCodeFilter from 'components/Modules/rating/MasterCodeFilter';
import queryString from 'query-string';
import { ImportRatingQuery } from 'interface/masterCode.interface';
import { openNotificationWithIcon } from 'utils/notification';
import handleError401 from 'utils/handleError401';
import * as XLSX from 'xlsx';
import Button from 'components/Button';

export default function MasterErrorCodePage(): JSX.Element {
  const history = useHistory();

  const queryParam = useQueryParam();

  const page = parseInt(queryParam.get('page') + '') || 1;

  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const code = queryParam.get('prod_origin_code') || undefined;
  const code_name = queryParam.get('error_code') || undefined;

  const masterCodeParams = {
    code,
    code_name,
  };

  const { mutate: uploadUserStatusRating, isLoading: uploadFileLoading } =
    useMutation(MasterCodeApi.postImportRating, {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Upload file thành công');
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', 'Upload file thất bại');
        if (error.response.data.status === 401) {
          handleError401();
        }
      },
    });

  const handleImport = (event: any) => {
    const files = event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target?.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(
            wb.Sheets[sheets[0]]
          ) as unknown as ImportRatingQuery;
          uploadUserStatusRating(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  function exportExcel(data: any, nameSheet: any, nameFile: any) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, nameSheet);
    XLSX.writeFile(wb, `${nameFile}.xlsx`);
  }

  const params = JSON.parse(JSON.stringify(masterCodeParams));

  const { data: MasterCodeDetail } = useQuery(
    ['getShipmentDetail', masterCodeParams],
    () => MasterCodeApi.getMasterCodeList(params)
  );

  const maxWidthPerCell = 600;
  const tableHeight = 600;
  const rowKey = 'id';
  const dataSource = [] as any;

  const columns: any = [
    {
      title: 'Mã nguồn gốc',
      dataIndex: 'code',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'Nguồn gốc',
      dataIndex: 'code_name',
    },
    {
      title: 'Mã Lỗi',
      dataIndex: 'description',
    },
  ];

  const dataTable = calculateColumnsWidth(
    columns,
    dataSource as [],
    maxWidthPerCell
  );

  const onFinish = (values: any) => {
    history.push('/master-code');
  };

  const handleSearch = (values: any) => {
    const search = queryString.stringify(values);

    history.push({
      pathname: '/master-code',
      search: `${search}&page=1`,
    });
  };

  return (
    <MainLayout>
      <MasterCodeFilter handleSearch={handleSearch} />

      <div className="flex flex-row-reverse">
        <label htmlFor="export-file">
          <Button
            onClick={() =>
              exportExcel(
                MasterCodeDetail?.data,
                'MasterCode',
                'Danh sách mã lỗi'
              )
            }
          >
            <DownloadOutlined />
            Export
          </Button>
        </label>
        <label
          htmlFor="upload-file"
          className="bg-primary-50 opacity-100 rounded-2.5xl text-white para-bold-1 py-2 px-6 drop-shadow-button border-2"
        >
          <UploadOutlined />

          <span className="ml-2 para-2">Import</span>
          <input
            type="file"
            onChange={handleImport}
            accept=".xlsx,.xls"
            id="upload-file"
            className="hidden"
          />
        </label>
      </div>

      <div className="main-content">
        <Table
          scroll={{ x: dataTable.tableWidth, y: tableHeight }}
          columns={columns}
          className="customTable"
          dataSource={MasterCodeDetail?.data}
          size="small"
          pagination={{
            current: page,
            pageSize: page_size,
            total: MasterCodeDetail?.total,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50'],
            onChange: HocChangePagination(history),
          }}
        />
      </div>
    </MainLayout>
  );
}
