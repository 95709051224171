/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import oauth_clients from 'apis/OauthClientAPI';
import ClientForm from 'components/Modules/Clients/ClientForm';
import MainLayout from 'components/Modules/Layout/MainLayout';
import { EditOauthClient, IOauthClientDetail } from 'interface/oauth_client';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { openNotificationWithIcon } from 'utils/notification';

function EditClientPage(): JSX.Element {
  const [client, setClient] = useState<IOauthClientDetail>();

  const params = useParams<{ id: string }>();

  const { id } = params;

  const { mutate: getClient } = useMutation<IOauthClientDetail>(
    async () => await oauth_clients.getClient(id),
    {
      onSuccess: (data) => {
        setClient(data);
      },
      onError: (error: any) => {
        error.response.data.message.map((err: any) =>
          openNotificationWithIcon('error', err)
        );
      },
    }
  );

  useEffect(() => {
    getClient();
  }, []);

  const { mutate: updateClientMutate } = useMutation(
    async (payload: EditOauthClient) =>
      await oauth_clients.editClient({
        ...payload,
        id,
      }),
    {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Cập nhật thành công!');
      },
      onError: () => {
        openNotificationWithIcon('error', 'Cập nhật không thành công!');
      },
    }
  );

  const onSubmit = async (values: EditOauthClient) => {
    updateClientMutate(values);
  };
  return (
    <MainLayout>
      <ClientForm client={client} submit={onSubmit} />
    </MainLayout>
  );
}

export default EditClientPage;
