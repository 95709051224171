import { DownOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Select, Spin } from 'antd';
import Button from 'components/Button';
import { useState } from 'react';
import RangeValue from 'rc-picker';
import moment, { Moment } from 'moment';
import { IGiParams } from 'interface/gi.interface';

const { Option } = Select;
const { RangePicker } = DatePicker;

const MAX_RANGE = 30;

const GISStates = [
  { value: '', status: 'Tất cả' },
  { value: 'waiting', status: 'Chờ xuất kho' },
  { value: 'pending', status: 'Chờ vận chuyển' },
  { value: 'preparing', status: 'Đang vận chuyển' },
  { value: 'delivering', status: 'Đang được giao' },
  { value: 'completed', status: 'Đã được giao' },
  { value: 'returning', status: 'Đang trả' },
  { value: 'cancelled', status: 'Đã hủy' },
  { value: 'done', status: 'Đã nhập kho' },
];

interface IProps {
  isShowEditButton?: boolean;
  isEditButtonEnable?: boolean;
  handleSearch: (values: any) => void;
  handleConfirmExport?: () => void;
  values: IGiParams;
  isLoading?: boolean;
}

export default function GIDetailFilter(props: IProps): JSX.Element {
  const {
    isShowEditButton = false,
    isEditButtonEnable,
    handleSearch,
    handleConfirmExport,
    values,
    isLoading,
  } = props;

  const [dates, setDates] = useState<Moment[]>([]);
  const [hackValue, setHackValue] = useState<any>();
  const [value, setValue] = useState<Moment[]>([moment(), moment()]);
  // const [isLoading, setLoading] = useState<boolean>(false);

  const onFinish = (values: any) => {
    const params = {
      ...values,
      fromDate: value?.[0]?.toDate().toLocaleDateString('fr-CA'),
      toDate: value?.[1]?.toDate().toLocaleDateString('fr-CA'),
    };
    Object.keys(params).forEach((k) => {
      if (params[k] == '') params[k] = undefined;
    });
    console.log({ params });
    // setLoading(true);
    handleSearch(params);
    // setLoading(false);
  };

  const disabledDate = (current: Moment) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > MAX_RANGE;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > MAX_RANGE;
    return tooEarly || tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  return (
    <div className="flex justify-between items-center mt-8 mb-10">
      <Spin size="large" spinning={isLoading}>
        <Form
          layout="inline"
          className="items-center"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={values}
        >
          <Form.Item name="search">
            <Input placeholder="Mã GI" />
          </Form.Item>

          <Form.Item name="status">
            <Select placeholder="Trạng thái">
              {GISStates.map((status) => (
                <Option value={status.value} key={status.value}>
                  {status.status}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="plant">
            <Input
              placeholder="Đơn vị xuất"
              suffix={<DownOutlined />}
              className="w-32"
            />
          </Form.Item>

          <Form.Item name="receivingPlant">
            <Input
              placeholder="Đơn vị nhận"
              suffix={<DownOutlined />}
              className="w-32"
            />
          </Form.Item>

          <Form.Item name="createdBy">
            <Input
              placeholder="Người tạo GI"
              suffix={<DownOutlined />}
              className="w-32"
            />
          </Form.Item>

          <Form.Item name="grCreatedBy">
            <Input
              placeholder="Người tạo GR"
              suffix={<DownOutlined />}
              className="w-32"
            />
          </Form.Item>

          <Form.Item name="shipper">
            <Input
              placeholder="Shipper"
              suffix={<DownOutlined />}
              className="w-32"
            />
          </Form.Item>

          <Form.Item name="vehicleType">
            <Input
              placeholder="Phương tiện"
              suffix={<DownOutlined />}
              className="w-32"
            />
          </Form.Item>

          <RangePicker
            value={hackValue || value}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val as Moment[])}
            onChange={(val) => setValue(val as Moment[])}
            onOpenChange={onOpenChange}
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          />

          <Form.Item>
            <Button type="submit">Tìm kiếm</Button>
          </Form.Item>
        </Form>
      </Spin>
      {isShowEditButton && (
        <Button
          borderRadius="md"
          disabled={!isEditButtonEnable}
          onClick={handleConfirmExport}
        >
          Điều chỉnh phiếu xuất
        </Button>
      )}
    </div>
  );
}
