import { Form, DatePicker, Input, Select, Button, Row, Col, Space } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  onFinish: (values: any) => void;
}

export default function BillOfLadingFilterForm(props: IProps): JSX.Element {
  const { onFinish } = props;
  const [form] = Form.useForm();

  const dateFormat = 'DD/MM/YYYY';
  const today = new Date();

  const initialFormValues = {
    date: [moment(today, dateFormat), moment(today, dateFormat)],
    shipper: '',
    gi: '',
    plant1: undefined,
    plant2: undefined,
    status: undefined,
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={(values) => onFinish(values)}
      initialValues={initialFormValues}
      className="bg-white p-4 mb-4"
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Form.Item name="date" label="Date">
            <RangePicker
              format={dateFormat}
              onCalendarChange={(dates, info) => console.log(dates, info)}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6}>
          <Form.Item name="shipper" label="Ten shipper">
            <Input placeholder="Tên shipper" />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6}>
          <Form.Item name="plant1" label="Plant xuat">
            <Select
              showSearch
              placeholder="Chon plant xuat"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="tom">Tom</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6}>
          <Form.Item name="plant2" label="Plant nhan">
            <Select
              showSearch
              placeholder="Chon plant nhan"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Option value="1">Not Identified</Option>
              <Option value="2">Closed</Option>
              <Option value="3">Communicated</Option>
              <Option value="4">Identified</Option>
              <Option value="5">Resolved</Option>
              <Option value="6">Cancelled</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={6}>
          <Form.Item name="status" label="Trang thai">
            <Select
              placeholder="Chon trang thai"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Option value="1">Dang giao</Option>
              <Option value="2">Da giao</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col className="gutter-row" offset={12} span={6}>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>

              <Button htmlType="submit" onClick={() => form.resetFields()}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
