import axiosClient from './axiosClient';
import { IResponse } from '../interface/base.interface';
const path = 'abe/user';

export type UpdateUserVars = {
  userId: number;
  plantCode: string;
  vehicleType: VehicleType;
};
export enum VehicleEnum {
  CAR = 'car',
  MOTOBIKE = 'motobike',
  PLANE = 'plane',
}
export enum VehicleEnum_Display {
  CAR = 'Xe hơi',
  MOTOBIKE = 'Xe gắn máy',
  PLANE = 'Máy bay',
  ems = 'EMS',
}
export type VehicleType = VehicleEnum;
export type VehicleType_Display = VehicleEnum_Display;

const UserApi = {
  updateUser: ({ userId, ...data }: UpdateUserVars): Promise<IResponse> => {
    const url = `/${path}/${userId}`;
    return axiosClient.put(url, data);
  },
};

export default UserApi;
