import {
  CalendarOutlined,
  CloseCircleOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { DatePicker, Divider, Modal, Table } from 'antd';
import viLocale from 'antd/es/date-picker/locale/vi_VN';
import RouteApi from 'apis/RouteApi';
import clsx from 'clsx';
import { ISchedule } from 'interface/route.interface';
import moment, { Moment } from 'moment';
import 'moment/locale/vi';
import { useEffect, useMemo, useRef, useState } from 'react';
import { openNotificationWithIcon } from 'utils/notification';
import './ScheduleTable.css';

const locale = {
  ...viLocale,
  lang: {
    ...viLocale.lang,
    monthFormat: 'MMMM',
  },
};

const initColumns = [
  {
    title: 'Mã công ty',
    dataIndex: 'departmentCode',
  },
  {
    title: 'Mã khu vực (mã tuyến)',
    dataIndex: 'routeCode',
  },
  {
    title: 'Tên khu vực',
    dataIndex: 'routeName',
  },
  {
    title: 'Mã khu vực (theo SAP)',
    dataIndex: 'routeCodeSap',
  },
  {
    title: 'Tên khu vực (theo SAP)',
    dataIndex: 'routeNameSap',
  },
  {
    title: 'Mã chi nhánh',
    dataIndex: 'orgCode',
  },
  {
    title: 'Tên chi nhánh',
    dataIndex: 'orgName',
  },
];

const weekDayNames = [
  'CN',
  'Thứ 2',
  'Thứ 3',
  'Thứ 4',
  'Thứ 5',
  'Thứ 6',
  'Thứ 7',
];

const nthDays = [
  'firstDay',
  'secondDay',
  'thirdDay',
  'fourthDay',
  'fifthDay',
  'sixthDay',
  'seventhDay',
];

export default function ScheduleTable(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const [schedules, setSchedules] = useState<ISchedule[]>([]);

  const [nthWeek, setNthWeek] = useState<number>(0);

  const [scheduleMonth, setScheduleMonth] = useState<Moment>(
    moment().startOf('month')
  );

  const [uploadYear, setUploadYear] = useState<Moment>();

  const [monthPickerOpen, setMonthPickerOpen] = useState<boolean>(false);

  const [yearPickerOpen, setYearPickerOpen] = useState<boolean>(false);

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [modalContent, setModalContent] = useState<JSX.Element>();

  // Get schedules when month changes
  useEffect(() => {
    getSchedules(scheduleMonth);
  }, [scheduleMonth]);

  // Recalculate columns when
  const columns = useMemo(() => {
    const dateColumns = [];

    const monthEnd = moment(scheduleMonth).endOf('month');

    const weekStart = moment(scheduleMonth).add(nthWeek, 'weeks');

    const weekEnd = moment(weekStart).add(7, 'days');

    // Can go left if week start is after month start
    const canGoLeft = weekStart.isAfter(scheduleMonth);

    // Can go right if week end is before month end
    const canGoRight = weekEnd.isBefore(monthEnd);

    // Loop through each day of the week
    for (let i = 0; i < 7; i++) {
      // Current day of the week
      const day = moment(weekStart).add(i, 'days');

      // Vietnamese week day name
      const weekDayName = weekDayNames[day.get('day')];

      // Only show left arrow for first column
      const showLeftArrow = i === 0;

      // Only show right arrow for last column
      const showRightArrow = i === 6;

      // This day is not in the current month if it's month is different from monthStart's month
      const dayIsNotInMonth = day.get('month') !== scheduleMonth.get('month');

      dateColumns.push({
        title: () => (
          <div className="flex items-center">
            {showLeftArrow && (
              <DoubleLeftOutlined
                onClick={canGoLeft ? preWeek : undefined}
                className={clsx(
                  'mr-4',
                  !canGoLeft && 'cursor-not-allowed opacity-20'
                )}
              />
            )}

            <span
              className={clsx(
                'para-bold-2 text-primary-50',
                dayIsNotInMonth && 'opacity-20'
              )}
            >
              {weekDayName} <br /> {day.format('DD/MM/YYYY')}
            </span>

            {showRightArrow && (
              <DoubleRightOutlined
                onClick={canGoRight ? nextWeek : undefined}
                className={clsx(
                  'ml-4',
                  !canGoRight && 'cursor-not-allowed opacity-20'
                )}
              />
            )}
          </div>
        ),
        render: (text: string, record: ISchedule) => {
          const { assignments } = record;

          // Assignment index based on day
          // Example: assignment = "a,b,c,d,e"
          // day = 3 => index = 2 => shipper = "c"
          const index = day.get('date') - 1;

          const shipper = assignments?.[index];

          if (dayIsNotInMonth || !shipper) {
            return <Divider />;
          }

          return shipper;
        },
        dataIndex: nthDays[i],
      });
    }

    return [...initColumns, ...dateColumns];
  }, [schedules, nthWeek]);

  // Go to next week
  function nextWeek() {
    setNthWeek((prevState) => prevState + 1);
  }

  // Go to previous week
  function preWeek() {
    setNthWeek((prevState) => prevState - 1);
  }

  function closeModal() {
    setModalVisible(false);
  }

  async function getSchedules(date: Moment) {
    setLoading(true);

    try {
      const data = await RouteApi.getSchedules({
        month: date.get('month'),
        year: date.get('year'),
      });

      console.log('Get schedules data (before mapping):', data);

      // Map assignment to string array
      data.forEach((x) => {
        x.assignments = x.assignment?.split(',');
      });

      console.log('Get schedules data (after mapping):', data);

      setSchedules(data);

      // Reset UI to show first week of the month
      setNthWeek(0);
    } catch (error) {
      console.log('Get schedules error:', error);
    } finally {
      setLoading(false);
    }
  }

  // On month pick
  function handleMonthChange(date: Moment | null) {
    if (!date) {
      return;
    }

    // Set month
    setScheduleMonth(date);
  }

  // Open month picker on calendar click
  function handleCalendarClick() {
    setMonthPickerOpen(true);
  }

  // Open year picker on upload file click
  function handleUploadFileClick() {
    setYearPickerOpen(true);
    setUploadYear(undefined);
  }

  // On year pick
  function handleYearChange(date: Moment | null) {
    if (!date) {
      return;
    }

    // Set upload year
    setUploadYear(date);

    // Open file picker
    fileInput.current?.click();
  }

  // On file chosen
  async function handleUploadFileChoose(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    // Only upload if there is a file
    if (!e.target.files || !e.target.files.length) {
      return;
    }

    const file = e.target.files[0];

    const fileExtension = file.name.split('.').pop() || '';

    // File type must be xls or xlsx
    if (!['xls', 'xlsx'].includes(fileExtension)) {
      openNotificationWithIcon(
        'error',
        'Chỉ chấp nhận file có đuôi .xls hoặc .xlsx'
      );
      return;
    }

    if (!uploadYear) {
      openNotificationWithIcon(
        'error',
        'Hãy chọn năm để cập nhật lịch giao nhận'
      );
      return;
    }

    try {
      // Upload schedules
      const data = await RouteApi.uploadSchedules(uploadYear.get('year'), file);

      console.log('Upload schedules data:', data);

      // If there is failure, alert errors in modal
      if (data.failure.length > 0) {
        setModalContent(
          <>
            {data.failure.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </>
        );

        setModalVisible(true);
      }
      // Else alert success
      else {
        openNotificationWithIcon(
          'success',
          'Cập nhật lịch giao nhận thành công'
        );
      }

      // Refresh schedules data
      getSchedules(scheduleMonth);
    } catch (error) {
      console.log('Upload schedules error:', error);
      openNotificationWithIcon(
        'error',
        'Đã có lỗi xảy ra khi cập nhật lịch giao nhận'
      );
    } finally {
      // Clear file input value
      e.target.value = '';
    }
  }

  return (
    <>
      <Modal
        title={
          <div className="text-[#ff4d4f] flex items-center gap-3">
            <CloseCircleOutlined style={{ fontSize: '150%' }} />
            <span>Một vài dữ liệu không hợp lệ</span>
          </div>
        }
        visible={modalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          style: { background: '#00538f', color: 'white', fontWeight: 700 },
        }}
      >
        {modalContent}
      </Modal>

      <div className="flex flex-row-reverse">
        <div className="relative">
          <button
            className="flex items-center px-2 py-1 border rounded-full text-primary-50 border-primary-50"
            onClick={handleUploadFileClick}
          >
            <UploadOutlined />

            <span className="ml-2 para-2">Upload File</span>
          </button>

          <DatePicker
            open={yearPickerOpen}
            onOpenChange={setYearPickerOpen}
            picker="year"
            locale={locale}
            value={uploadYear}
            onChange={handleYearChange}
            className="absolute inset-0 invisible"
          />
        </div>

        <input
          ref={fileInput}
          hidden
          type="file"
          onChange={handleUploadFileChoose}
          accept=".xlsx,.xls"
        />
      </div>

      <div className="ml-[70%] relative w-fit">
        <button
          className="flex items-center text-primary-50"
          onClick={handleCalendarClick}
        >
          <span className="pr-2 para-2 text-primary-50">Lịch giao</span>

          <CalendarOutlined />
        </button>

        <DatePicker
          open={monthPickerOpen}
          onOpenChange={setMonthPickerOpen}
          picker="month"
          locale={locale}
          value={scheduleMonth}
          onChange={handleMonthChange}
          className="absolute inset-0 invisible"
          monthCellRender={(date) => (
            <div className="ant-picker-cell-inner w-fit">
              {date.format('[Tháng] MM')}
            </div>
          )}
        />
      </div>

      <Table
        className="customTable"
        dataSource={schedules}
        columns={columns}
        pagination={false}
        loading={loading}
      />
    </>
  );
}
