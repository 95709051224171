import { Button, Form, Input, Modal, Select } from 'antd';
import { IPermissionGroup, IUser } from 'interface/user.interface';
import { useEffect } from 'react';

const { Option } = Select;

interface IProps {
  isModalVisible: boolean;
  onCancel: () => void;
  handleUpdateUser: (data: any) => void;
  currentUser?: IUser;
  groups?: IPermissionGroup[];
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function UserModal(props: IProps): JSX.Element {
  const { isModalVisible, onCancel, handleUpdateUser, currentUser, groups } =
    props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [currentUser, form]);

  return (
    <Modal
      title="Phân quyền người dùng"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        name="role_based_auth"
        onFinish={handleUpdateUser}
        className="bg-white p-2 gap-4 lg:w-90 md:w-90 sm:w-100"
        initialValues={currentUser}
        {...formLayout}
      >
        <Form.Item
          label="Mã nhân viên"
          name="id"
          // initialValue={currentUser?.id}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Họ và Tên"
          name="fullName"
          // initialValue={currentUser?.fullName}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          // initialValue={currentUser?.email}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Điện thoại"
          name="phoneNumber"
          // initialValue={currentUser?.phoneNumber}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          className="hidden"
          name="jobTitleCode"
          // initialValue={currentUser?.jobTitleCode}
        >
          <Input className="hidden" />
        </Form.Item>

        <Form.Item
          label="Chức vụ"
          name="jobTitle"
          // initialValue={currentUser?.jobTitle}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Tên bộ phận/Cửa hàng"
          name="departmentName"
          // initialValue={currentUser?.departmentName}
        >
          <Input className="w-full" disabled />
        </Form.Item>

        <Form.Item
          label="Nhóm quyền"
          name="group"
          // initialValue={currentUser?.group}
        >
          <Select
            disabled={!currentUser?.groups?.length || !currentUser.groups}
          >
            {groups
              //?.filter((x) => currentUser?.groups?.includes(x.name))
              ?.map((group) => (
                <Option key={group.name} value={group.name}>
                  {group.title}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Các Nhóm quyền"
          name="groups"
          // initialValue={currentUser?.group}
        >
          <Select mode="multiple" allowClear>
            {groups?.map((group) => (
              <Option key={group.name} value={group.name}>
                {group.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item className="flex items-center justify-center">
          <Button type="primary" htmlType="submit" className="bg-primary">
            CHỈNH SỬA
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
