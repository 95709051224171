import { Button, Form, Input, Modal } from 'antd';
import { IRoute } from 'interface/route.interface';
import { useEffect } from 'react';

interface IProps {
  isModalVisible: boolean;
  onCancel: () => void;
  handleUpdateRoute: (data: IRoute) => void;
  currentRoute?: IRoute;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function RouteModal(props: IProps): JSX.Element {
  const { isModalVisible, onCancel, handleUpdateRoute, currentRoute } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [currentRoute]);

  return (
    <Modal
      title="Cập nhật tuyến đường"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        name="role_based_auth"
        onFinish={handleUpdateRoute}
        className="bg-white p-2 gap-4 lg:w-90 md:w-90 sm:w-100"
        initialValues={currentRoute}
        {...formLayout}
      >
        <Form.Item
          label="Mã Tuyến Đường"
          name="routeId"
          // initialValue={currentUser?.id}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Mã công ty"
          name="orgCode"
          // initialValue={currentUser?.id}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Mã khu vực"
          name="routeCode"
          // initialValue={currentUser?.fullName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tên Khu vực"
          name="routeName"
          // initialValue={currentUser?.email}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mã khu vực(SAP)"
          name="routeCodeSap"
          // initialValue={currentUser?.phoneNumber}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tên khu vực(SAP)"
          name="routeCodeNameSap"
          // initialValue={currentUser?.jobTitle}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mã chi nhánh"
          name="departmentCode"
          // initialValue={currentUser?.jobTitle}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Tên chi nhánh"
          name="departmentName"
          // initialValue={currentUser?.jobTitle}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Mã cửa hàng"
          name="plantCode"
          // initialValue={currentUser?.departmentName}
        >
          <Input className="w-full" disabled />
        </Form.Item>

        <Form.Item
          label="Tên cửa hàng"
          name="plantName"
          // initialValue={currentUser?.departmentName}
        >
          <Input className="w-full" disabled />
        </Form.Item>

        <Form.Item className="flex items-center justify-center">
          <Button type="primary" htmlType="submit" className="bg-primary">
            CẬP NHẬT
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
