import { IPagingResponse, IResponse } from 'interface/base.interface';
import axiosClient from './axiosClient';
import {
  IGetMasterCodeQuery,
  ImportRatingQuery,
} from 'interface/masterCode.interface';

const path = 'abe/code';

const MasterCodeApi = {
  getMasterCodeList: (
    params?: IGetMasterCodeQuery
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.get(`/${path}`, { params });
  },

  postImportRating: (
    params?: ImportRatingQuery
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.post(`/${path}/import-code`, {
      data: params,
    });
  },
};

export default MasterCodeApi;
