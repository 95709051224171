import { IPagingResponse } from 'interface/base.interface';
import axiosClient from './axiosClient';
import {
  IControlGi,
  IControlShipment,
  IGetShipmentsQuery,
  IShipment,
  IShipmentDetail,
} from 'interface/shipment.interface';

const path = 'abe/shipment';

const ShipmentApi = {
  getShipments: (
    params?: IGetShipmentsQuery
  ): Promise<IPagingResponse<IShipment>> => {
    return axiosClient.get(`/${path}`, { params });
  },
  getShipmentDetail: ({
    shipmentCode,
    ...params
  }: {
    shipmentCode: string;
    page?: number;
    page_size?: number;
  }): Promise<IPagingResponse<IShipmentDetail>> => {
    return axiosClient.get(`/${path}/${shipmentCode}`, { params });
  },
  controlShipment: ({ shipmentCode, ...data }: IControlShipment) => {
    return axiosClient.post(`/${path}/${shipmentCode}`, data);
  },
  controlGi: ({ giDocument, ...data }: IControlGi) => {
    return axiosClient.post(`/${path}/gi/${giDocument}`, data);
  },
};

export default ShipmentApi;
