import { Button, Form, Input, Modal, Select } from 'antd';
import { VehicleEnum, VehicleEnum_Display } from 'apis/UserClient';
import { IUser } from 'interface/user.interface';
import { useEffect } from 'react';

interface IProps {
  isModalVisible: boolean;
  onCancel: () => void;
  handleUpdateUser: (data: any) => void;
  currentUser?: IUser;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

export default function UserPlantModal(props: IProps): JSX.Element {
  const { isModalVisible, onCancel, handleUpdateUser, currentUser } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [currentUser]);

  return (
    <Modal
      title="Cập nhật thông tin User"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        name="role_based_auth"
        onFinish={handleUpdateUser}
        className="bg-white p-2 gap-4 lg:w-90 md:w-90 sm:w-100"
        initialValues={currentUser}
        {...formLayout}
      >
        <Form.Item
          label="Mã nhân viên"
          name="id"
          // initialValue={currentUser?.id}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Họ và Tên"
          name="fullName"
          // initialValue={currentUser?.fullName}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          // initialValue={currentUser?.email}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Điện thoại"
          name="phoneNumber"
          // initialValue={currentUser?.phoneNumber}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Mã của hàng"
          name="plantCode"
          initialValue={currentUser?.plant?.code}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tên cửa hàng"
          name="plantName"
          initialValue={currentUser?.plant?.name}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Điện thoại"
          name="phoneNumber"
          // initialValue={currentUser?.phoneNumber}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          className="hidden"
          name="jobTitleCode"
          // initialValue={currentUser?.jobTitleCode}
        >
          <Input className="hidden" />
        </Form.Item>

        <Form.Item
          label="Chức vụ"
          name="jobTitle"
          // initialValue={currentUser?.jobTitle}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Tên bộ phận/Cửa hàng"
          name="departmentName"
          // initialValue={currentUser?.departmentName}
        >
          <Input className="w-full" disabled />
        </Form.Item>

        <Form.Item
          label="Loại phương tiện"
          name="vehicleType"
          required={true}
          initialValue={currentUser?.vehicleType}
          // rules={[require:'true']}
        >
          {/* <Input disabled /> */}
          <Select defaultValue={'Select vehicle type'}>
            <Option value={VehicleEnum.CAR}>{VehicleEnum_Display.CAR}</Option>
            <Option value={VehicleEnum.MOTOBIKE}>
              {VehicleEnum_Display.MOTOBIKE}
            </Option>
            <Option value={VehicleEnum.PLANE}>
              {VehicleEnum_Display.PLANE}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item className="flex items-center justify-center">
          <Button type="primary" htmlType="submit" className="bg-primary">
            CHỈNH SỬA
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
