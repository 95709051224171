export const DEFAULT_PAGE_SIZE = 10;

export const shipmentStates = [
  { value: 'active', status: 'Đang hoạt động' },
  { value: 'done', status: 'Hoàn thành' },
  { value: 'pause', status: 'Tạm dừng' },
  { value: 'stop', status: 'Thu hồi' },
];

export const ratingStatus = [
  { value: 'done', status: 'Đã đánh giá' },
  { value: 'expried', status: 'Quá hạn đánh giá' },
  { value: 'waiting', status: 'Chờ đánh giá' },
  { value: 'process', status: 'Đang đánh giá' },
];

export const ratingGiStatusPHHH = [
  { value: 'reject', status: 'Từ chối phản hồi' },
  { value: 'sent', status: 'Đã gửi phản hồi' },
  { value: 'unsent', status: 'Chưa gửi phản hồi' },
];
