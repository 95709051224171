/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Input, Table, Tag } from 'antd';
import PermissionApi from 'apis/PermissionApi';
import {
  default as RoleBasedAPI,
  default as RoleBasedAuth,
} from 'apis/RoleBasedAPI';
import UserApi, { UpdateUserVars } from 'apis/UserClient';
import Button from 'components/Button';
import MainLayout from 'components/Modules/Layout/MainLayout';
import UserModal from 'components/Modules/User/UserModal';
import UserPlantModal from 'components/Modules/User/UserPlantModal';
import useQueryParam from 'hooks/useQueryPrams';
import { IGetUserQuery, IUser } from 'interface/user.interface';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import handleError401 from 'utils/handleError401';
import { HocChangePagination } from 'utils/HocChangePagination';
import { openNotificationWithIcon } from 'utils/notification';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';

export default function UserManagementPage(): JSX.Element {
  const [isPermissionModalVisible, setIsPermissionModalVisible] =
    useState<boolean>(false);
  const [isUserPlantModalVisible, setIsUserPlantModalVisible] =
    useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUser>();

  const history = useHistory();

  const queryParam = useQueryParam();

  const page = parseInt(queryParam.get('page') + '') || 1;

  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const search = queryParam.get('search') || undefined;
  const fullName = queryParam.get('fullName') || undefined;
  const email = queryParam.get('email') || undefined;
  const jobTitleCode = queryParam.get('jobTitleCode') || undefined;
  const departmentCode = queryParam.get('departmentCode') || undefined;
  const plantCode = queryParam.get('plantCode') || undefined;

  const userParams: IGetUserQuery = {
    page,
    page_size,
    search,
    fullName,
    email,
    jobTitleCode,
    departmentCode,
    plantCode,
  };

  const params = JSON.parse(JSON.stringify(userParams));

  const {
    data,
    refetch: userRefetch,
    isLoading: getUsersLoading,
  } = useQuery(['getUsers', userParams], () => RoleBasedAuth.getUsers(params));

  const { data: groupData } = useQuery(
    'getGroups',
    () => PermissionApi.getGroups({ page: 1, page_size: 9999 }),
    {
      onError: (error: any) => {
        if (error.response.data.status === 401) {
          handleError401();
        }
      },
    }
  );

  const { mutate: setRole, isLoading: setRoleLoading } = useMutation(
    RoleBasedAPI.setRole,
    {
      onSuccess: () => {
        userRefetch();
        openNotificationWithIcon('success', 'Cập nhật quyền thành công!');
        setIsPermissionModalVisible(false);
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', error.response.data.message[0]);
      },
    }
  );

  const { mutate: assignGroups, isLoading: processing } = useMutation(
    PermissionApi.assignUserGroups,
    {
      onSuccess: () => {
        userRefetch();
        openNotificationWithIcon('success', 'Cấp quyền cho user thành công');
        setIsPermissionModalVisible(false);
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', error.response.data.message[0]);
      },
    }
  );

  const { mutate: setUserPlant, isLoading: setUserPlantLoading } = useMutation(
    UserApi.updateUser,
    {
      onSuccess: () => {
        userRefetch();
        openNotificationWithIcon('success', 'Cập nhật thông tin thành công!');
        setIsUserPlantModalVisible(false);
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', error.response.data.message[0]);
      },
    }
  );

  const handleUpdateUser = (data: any) => {
    if (!currentUser) return;
    let isNotUpdateGroups = true;
    let isNotUpdateGroup = true;

    const payload = {
      id: currentUser?.id,
      group: data.group,
    };

    if (data.groups) {
      const equal =
        JSON.stringify(data?.groups?.sort()) ===
        JSON.stringify(currentUser?.groups?.sort());
      if (!equal) {
        assignGroups({ userId: currentUser.id, groups: data.groups });
        isNotUpdateGroups = false;
      }
    }

    if (data.group !== currentUser.group) {
      setRole(payload);
      isNotUpdateGroup = false;
    }

    if (isNotUpdateGroups && isNotUpdateGroup)
      setIsPermissionModalVisible(false);
  };

  const handleUpdateUserPlant = (data: any) => {
    if (!currentUser) return;
    const payload: UpdateUserVars = {
      userId: currentUser?.id,
      plantCode: data.plantCode,
      vehicleType: data.vehicleType,
    };

    setUserPlant(payload);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Họ tên người dùng',
      dataIndex: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'chức vụ',
      dataIndex: 'jobTitle',
    },
    {
      title: 'Bộ phận',
      dataIndex: 'departmentName',
    },
    {
      title: 'Mã plant',
      dataIndex: 'code',
      render: (_: any, record: IUser) => {
        return <>{record?.plant?.code}</>;
      },
    },
    {
      title: 'Tên plant',
      dataIndex: 'name',
      render: (_: any, record: IUser) => {
        return <>{record?.plant?.name}</>;
      },
    },
    {
      title: 'Email Người quản lý',
      dataIndex: 'managerEmail',
      render: (_: any, record: IUser) => {
        return <>{record?.managerEmail}</>;
      },
    },
    {
      title: 'Nhóm quyền',
      dataIndex: 'group',
      render: (record: any) => {
        const group = groupData?.data?.find((group) => group.name === record);
        return <>{group?.title}</>;
      },
    },
    {
      title: 'Thao tác',
      render: (record: any) => (
        <>
          <Tag
            color="blue"
            className="cursor-pointer mb-1"
            onClick={() => {
              setIsPermissionModalVisible(true);
              setCurrentUser(record);
            }}
          >
            Phân quyền
          </Tag>
          <Tag
            color="cyan"
            className="cursor-pointer"
            onClick={() => {
              setIsUserPlantModalVisible(true);
              setCurrentUser(record);
            }}
          >
            Cập nhật thông tin
          </Tag>
        </>
      ),
    },
  ];

  const onFinish = (values: any) => {
    const search: Partial<IGetUserQuery> = JSON.parse(
      JSON.stringify({
        ...values,
        page: 1,
        page_size: page_size,
      })
    );

    history.push({
      pathname: location.pathname,
      search: updateQueryStringParameter(location.search, search),
    });
  };

  return (
    <MainLayout isLoading={setUserPlantLoading || setRoleLoading}>
      {/* <Input
        placeholder="Tìm kiếm theo Email"
        style={{ width: 170 }}
        onChange={(e) => handleSearchUser(e)}
      /> */}

      <Form
        layout="inline"
        className="items-center"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="fullName">
          <Input placeholder="Họ và tên" className="w-44" />
        </Form.Item>

        <Form.Item name="email">
          <Input placeholder="Email" className="w-44" />
        </Form.Item>

        <Form.Item name="jobTitleCode">
          <Input placeholder="Job Title" className="w-44" />
        </Form.Item>

        <Form.Item name="departmentCode">
          <Input placeholder="Department" className="w-44" />
        </Form.Item>

        <Form.Item name="plantCode">
          <Input placeholder="Chi nhánh" className="w-44" />
        </Form.Item>

        <Form.Item>
          <Button type="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>

      <div className="main-content">
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          className="customTable"
          dataSource={data?.data}
          size="small"
          loading={getUsersLoading}
          pagination={{
            current: page,
            pageSize: page_size,
            total: data?.total,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50'],
            onChange: HocChangePagination(history),
          }}
        />
        <UserModal
          isModalVisible={isPermissionModalVisible}
          onCancel={() => setIsPermissionModalVisible(false)}
          handleUpdateUser={handleUpdateUser}
          currentUser={currentUser}
          groups={groupData?.data}
        />
        <UserPlantModal
          isModalVisible={isUserPlantModalVisible}
          onCancel={() => setIsUserPlantModalVisible(false)}
          handleUpdateUser={handleUpdateUserPlant}
          currentUser={currentUser}
        />
      </div>
    </MainLayout>
  );
}
