/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Input, Form } from 'antd';
import { IVehicle } from 'interface/vehicle.interface';
import { Select } from 'antd';

const { Option } = Select;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'select' | 'text';
  record: IVehicle;
  index: number;
  children: React.ReactNode;
  options?: { value: string; label: string }[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  options,
  ...restProps
}) => {
  const inputNode =
    inputType === 'select' && options ? (
      <Select>
        {options.map((option, index) => (
          <Option key={index} value={option.value} defaultValue={options[0]}>
            {option.label}
          </Option>
        ))}
      </Select>
    ) : (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
