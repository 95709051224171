import { UserManagementPage } from 'pages';
import BillOfLadingManagementPage from 'pages/BillOfLandingManagement';
import BillOfLadingDetailPage from 'pages/BillOfLandingManagement/BillOfLadingDetail';
import { ClientsManagementPage, NewClientPage } from 'pages/ClientsManagement';
import GIManagementPage from 'pages/GIManagement';
import EditGIPage from 'pages/GIManagement/EditGI';
import GIDetailPage from 'pages/GIManagement/GIDetail';
import HomePage from 'pages/Home';
import PlantManagementPage from 'pages/PlantManagement';
import PlantDetailPage from 'pages/PlantManagement/PlantDetail';
import ShipmentManagementPage from 'pages/ShipmentManagement';
import ShipmentDetailPage from 'pages/ShipmentManagement/shipmentDetail';
import SignInPage from 'pages/SignIn';
// import TransportManagementPage from 'pages/TransportManagement';
import FunctionPage from 'pages/UserManagement/FuntionPage';
import GroupsPage from 'pages/UserManagement/GroupPage';

import RouteManagementPage from 'pages/RouteManagement';
import GeoMapPage from 'pages/TransportManagement/GeoMap';
import JobTitlePage from 'pages/UserManagement/TitlePage';
import MappingJobTitlePage from 'pages/UserManagement/MappingJobTitle';
import MasterErrorPage from 'pages/Rating/MasterCode';
import ErrorListPage from 'pages/Rating/RatingList';
import {
  Cockpit,
  ErrorCode,
  Goods,
  KPI,
  MasterData,
  MasterError,
  Quality,
  TopPerformer,
} from 'pages/PowerBiManagement';
import PermissionPage from 'pages/Rating/Permission';

const routes = [
  { path: '/', component: HomePage },
  { path: '/sign-in', component: SignInPage, isPublic: true },

  //gi
  { path: '/gi', component: GIManagementPage },
  { path: '/edit-gi', component: EditGIPage },
  { path: '/gi-detail', component: GIDetailPage },

  // transport management
  // {
  //   path: '/transport-management',
  //   component: TransportManagementPage,
  // },
  {
    path: '/geo-map',
    component: GeoMapPage,
  },

  //client management
  {
    path: '/clients-management',
    component: ClientsManagementPage,
  },
  {
    path: '/clients-management/new-client',
    component: NewClientPage,
  },
  // {
  //   path: '/clients-management/edit-client',
  //   component: EditClientPage,
  // },

  //plant management
  {
    path: '/plant',
    component: PlantManagementPage,
  },
  {
    path: '/plant/:code',
    component: PlantDetailPage,
  },

  //   user management
  {
    path: '/role-based-auth',
    component: UserManagementPage,
  },
  {
    path: '/groups',
    component: GroupsPage,
    isPublic: true,
  },
  {
    path: '/functions',
    component: FunctionPage,
  },
  {
    path: '/job-title',
    component: JobTitlePage,
  },
  {
    path: '/mapping-job-title',
    component: MappingJobTitlePage,
  },

  //   bill of lading management
  {
    path: '/bill-of-lading',
    component: BillOfLadingManagementPage,
  },
  {
    path: '/bill-of-lading:id',
    component: BillOfLadingDetailPage,
  },

  // shipment management
  {
    path: '/shipment',
    component: ShipmentManagementPage,
  },

  {
    path: '/shipment/:shipmentCode',
    component: ShipmentDetailPage,
  },

  // route management
  {
    path: '/route',
    component: RouteManagementPage,
  },
  {
    path: '/cockpit',
    component: Cockpit,
  },
  {
    path: '/goods',
    component: Goods,
  },
  {
    path: '/masterdata',
    component: MasterData,
  },
  {
    path: '/quality',
    component: Quality,
  },
  {
    path: '/kpi',
    component: KPI,
  },
  {
    path: '/top-performer',
    component: TopPerformer,
  },
  {
    path: '/master-error',
    component: MasterError,
  },
  {
    path: '/error-code',
    component: ErrorCode,
  },
  //Rating
  { path: '/rating', component: ErrorListPage },
  { path: '/master-code', component: MasterErrorPage },
  { path: '/permission-code', component: PermissionPage },
];

export default routes;
