import { Table } from 'antd';
import GiAPI from 'apis/GiAPI';
import { CheckIcon, ThreeDotIcon } from 'assets/svg';
import { IGi, IGiDetail, IGiDetailInfo } from 'interface/gi.interface';
import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { calculateColumnsWidth } from 'utils/calculateWidthColumn';
import formatDateWithTime from 'utils/formatDateWithTime';
import { HocChangePagination } from 'utils/HocChangePagination';
import { translateGIStatus } from 'utils/translateStatus';
import GIDetailModal from './GIDetailModal';
interface IProps {
  dataSource: any[];
  disableButton?: () => void;
  enableButton?: () => void;
  hasSelection: boolean;
  page: number;
  pageSize: number;
  total: number;
  getSelectedRow?: (documents: string[]) => void;
}

export default function GIDetailTable(props: IProps): JSX.Element {
  const {
    dataSource,
    disableButton,
    enableButton,
    hasSelection,
    page,
    total,
    getSelectedRow,
    pageSize,
  } = props;

  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentGi, setCurrentGi] = useState<string>('');

  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    if (!enableButton || !disableButton) return;

    if (selectedRowKeys.length > 0) {
      enableButton();
    } else {
      disableButton();
    }

    const documents = selectedRows.map((row: any) => row.document);

    getSelectedRow && getSelectedRow(documents);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  const handleShowGIDetailModal = (record: IGi) => {
    setIsModalVisible(true);
    setCurrentGi(record.document);
  };

  const { data: GIDetials } = useQuery(
    ['getGIDetails', currentGi],
    () => GiAPI.getGIDT(currentGi),
    { enabled: !!currentGi }
  );

  const columns: any = [
    {
      title: 'STO',
      dataIndex: 'sto',
      key: 'sto',
      fixed: 'left',
    },
    {
      title: 'DO',
      dataIndex: 'delivery',
      key: 'delivery',
      fixed: 'left',
    },
    {
      title: 'Mã GI',
      dataIndex: 'document',
      key: 'document',
      fixed: 'left',
    },
    {
      title: 'Người Tạo GI',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: 'Trạng Thái GI',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <span>{translateGIStatus(record.status)}</span>
      ),
    },
    {
      title: 'Mã Đơn Vị Xuất',
      dataIndex: 'plantCode',
      key: 'plantCode',
    },
    {
      title: 'Đơn Vị Xuất',
      dataIndex: 'plantName',
      key: 'plantName',
    },
    {
      title: 'Người Xuất Hàng',
      dataIndex: 'userRelease',
      key: 'userRelease',
    },
    {
      title: 'Ngày Xuất Hàng',
      dataIndex: 'timeRelease',
      key: 'timeRelease',
      render: (_: any, record: IGiDetailInfo) => (
        <span>
          {record?.timeRelease
            ? moment(record.timeRelease).format('DD/MM/YYYY')
            : ''}
        </span>
      ),
    },
    {
      title: 'Shipper Giao Hàng',
      dataIndex: 'shipperName',
      key: 'shipperName',
    },

    {
      title: 'Mã Đơn vị nhận',
      dataIndex: 'receivingPlantCode',
      key: 'receivingPlantCode',
    },
    {
      title: 'Tên Đơn vị nhận',
      dataIndex: 'receivingPlantName',
      key: 'receivingPlantName',
    },
    {
      title: 'Nhân Viên Nhận Hàng',
      dataIndex: 'userReceiving',
      key: 'userReceiving',
    },
    {
      title: 'Thời Gian Bắt Đầu Vận Chuyển',
      dataIndex: 'deliveryStartDate',
      key: 'deliveryStartDate',
    },
    {
      title: 'Thời Gian Nhận Hàng',
      dataIndex: 'timeReceiving',
      key: 'timeReceiving',
    },
    {
      title: 'Mã GR',
      dataIndex: 'grCode',
      key: 'grCode',
    },
    {
      title: 'Trạng Thái GR',
      dataIndex: 'grStatus',
      key: 'grStatus',
      // render: (_: any, record: any) => (
      //   <span>{translateGRStatus(record.status)}</span>
      // ),
    },
    {
      title: 'Người tạo GR',
      dataIndex: 'grCreatedBy',
      key: 'grCreatedBy',
    },
    {
      title: 'Ngày tạo GR',
      dataIndex: 'timeCreateGr',
      key: 'timeCreateGr',
      render: (_: any, record: IGi) => (
        <span>
          {record?.grCreatedAt
            ? moment(record.grCreatedAt).format('DD/MM/YYYY')
            : ''}
        </span>
      ),
    },
  ];

  const maxWidthPerCell = 600;
  const tableHeight = 500;
  const rowKey = 'id';

  // This helper function helps to calculate the width for each column
  // based on all table cells - column cell and source cell
  const dataTable = calculateColumnsWidth(
    columns,
    dataSource as [],
    maxWidthPerCell
  );

  return (
    <>
      <Table
        rowKey={rowKey}
        scroll={{ x: dataTable.tableWidth, y: tableHeight }}
        className="customTable"
        rowSelection={hasSelection ? rowSelection : undefined}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          // defaultCurrent: page,
          current: page,
          // defaultPageSize: 20,
          pageSize: pageSize,
          total: total,
          onChange: HocChangePagination(history),
        }}
      />
      {GIDetials && (
        <GIDetailModal
          gi={GIDetials}
          isVisible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
}
