/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGetUserQuery, IUser } from 'interface/user.interface';
import axiosClient from './axiosClient';

const RoleBasedAuth = {
  getUsers: async (
    params: IGetUserQuery
  ): Promise<{ data: IUser[]; total: number }> => {
    return await axiosClient.get('/abe/user', { params });
  },
  setRole: async (payload: { id: number; group: string }): Promise<any> => {
    return await axiosClient.put('/abe/user', payload);
  },
};
export default RoleBasedAuth;
