import React from 'react';
import { Button, Form, Input } from 'antd';
import { Select } from 'antd';

const { Option } = Select;
import {
  EditOauthClient,
  IOauthClientDetail,
  Scope,
} from 'interface/oauth_client';

interface Props {
  client?: IOauthClientDetail;
  submit: (values: EditOauthClient) => Promise<void>;
}

function ClientForm(props: Props): JSX.Element {
  const [form] = Form.useForm();

  const { submit, client } = props;

  const onSubmit = submit;

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="basic"
      fields={[
        {
          name: ['name'],
          value: client?.name,
        },
        {
          name: ['grants'],
          value: client?.grants[0],
        },
        {
          name: ['scope'],
          value: client?.scope,
        },
      ]}
      onFinish={onSubmit}
      form={form}
      className="m-auto w-2/5"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input client's name!" }]}
      >
        <Input placeholder="Enter client's name" />
      </Form.Item>
      <Form.Item
        label="Grants"
        name="grants"
        rules={[{ required: true, message: 'Please input grants!' }]}
      >
        <Select defaultValue={'Select a grant'}>
          <Option value="client_credentials">client_credentials</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Scope"
        name="scope"
        rules={[{ required: true, message: 'Please select an scope!' }]}
      >
        <Select defaultValue={'Select a scope'}>
          <Option value={Scope.Read}>Read</Option>
          <Option value={Scope.Write}>Write</Option>
          <Option value={Scope.ReadWrite}>Read and Write</Option>
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" className="w-full">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ClientForm;
