import { IPagingResponse, IResponse } from 'interface/base.interface';
import axiosClient from './axiosClient';
import {
  IGetRatingDtByRatingQuery,
  IGetRatingQuery,
  ImportRating,
  ImportRatingQuery,
  getStoDtoGiPhhhQuery,
  IResponseRating,
} from 'interface/rating.interface';

const path = 'abe/rating';

const RatingApi = {
  getRatingList: (
    params?: IGetRatingQuery
  ): Promise<IPagingResponse<IGetRatingQuery>> => {
    return axiosClient.get(`/${path}`, { params });
  },

  putRewaitingRating: (
    params?: IGetRatingQuery['gi_id']
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.put(`/${path}/rewaiting-rating/${params}`);
  },

  putReprocessRating: (
    params?: IGetRatingQuery['gi_id']
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.put(`/${path}/reprocess-rating/${params}`);
  },

  putUpdateInfoRating: (params?: any): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.put(`abe/rating-dt/${params['id']}`, params);
  },

  getRatingDt: (
    params?: IGetRatingQuery['gi_id']
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.get(`abe/rating-dt/${params}`);
  },

  putRejectRating: (
    params?: IGetRatingQuery['gi_id']
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.put(`/${path}/reject-rating/${params}`);
  },

  getRatingDtByRating: (
    params?: IGetRatingDtByRatingQuery['rating_id']
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.get(
      `abe/rating-dt/get-by-rating-m/?rating_id=${params}`,
      {
        params,
      }
    );
  },
  postImportRating: (
    params?: ImportRatingQuery
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.post(`/${path}/import_user_status_rating`, {
      data: params,
    });
  },

  getStoDtoGiPhhh: (
    params?: getStoDtoGiPhhhQuery
  ): Promise<IResponseRating> => {
    return axiosClient.post(`/${path}/get_sto_dto_gi_phhh`, {
      type: params?.type,
      gi_id: params?.gi_id,
    });
  },
};

export default RatingApi;
