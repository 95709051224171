/* eslint-disable react-hooks/rules-of-hooks */
import ReactMapGL, {
  Marker,
  WebMercatorViewport,
} from '@goongmaps/goong-map-react';
import * as turf from '@turf/turf';
import { BBox } from '@turf/turf';
import { Select, Spin } from 'antd';
import PlantAPI from 'apis/PlantAPI';
import TrackingShipperApi from 'apis/TrackingShipper';
import { DefaultIconUser, LocationIcon } from 'assets/svg';
import { MainLayout } from 'components';
import { useUser } from 'contexts/User';
import useInterval from 'hooks/useInterval';
import { ITrackingShipperResponse } from 'interface/tracking_shippers.interface';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

const { Option } = Select;

//Note: This key was taken from personal Goong account: akZkJkykY6tEMUlmKF3HX6J8dTTbUKcQXR4z6W4Q
const GOONG_MAPTILES_KEY =
  process.env.REACT_APP_GOONG_GOONG_MAPTILES_KEY ||
  'akZkJkykY6tEMUlmKF3HX6J8dTTbUKcQXR4z6W4Q';

const myShippersParam = {
  myShippers: true,
};

interface ICoordinates {
  latitude: number;
  longitude: number;
}
interface IViewPort extends ICoordinates {
  width: number;
  height: number;
  zoom: number;
  transitionDuration?: number;
  transitionInterpolator?: any;
}

interface IPlantCoordinates extends ICoordinates {
  zoom: number;
}

function GeoMapPage() {
  const plantCode = useUser().user?.plantCode;

  const isZoomRef = useRef<boolean>(false);

  if (!plantCode) {
    return <Spin />;
  }

  const [isZoom, setIsZoom] = useState(isZoomRef.current);

  const [viewport, setViewport] = useState<IViewPort>({} as IViewPort);

  const [userIdsOptions, setUserIdsOptions] = useState<
    { userId: number; userName: string }[]
  >([]);

  const [usersSelected, setUsersSelected] = useState<number[]>([]);

  const [plantLocation, setPlantLocation] = useState<IPlantCoordinates>();

  const [shippersLocations, setShippersLocations] = useState<
    ITrackingShipperResponse[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [visible, setVisible] = useState<boolean>(false);

  const { mutateAsync: getPlantLocation } = useMutation(PlantAPI.getPlant, {
    onSuccess: (data) => {
      setPlantLocation({
        ...plantLocation,
        latitude: +data.latitude,
        longitude: +data.longitude,
        zoom: 3,
      });

      setViewport({
        ...viewport,
        latitude: +data.latitude,
        longitude: +data.longitude,
      });
    },
  });

  const { mutateAsync: getShippers } = useMutation(
    TrackingShipperApi.getShippersLocation,
    {
      onSuccess: (data) => {
        setShippersLocations(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleGetAllShipper = useCallback(() => {
    getShippers(myShippersParam)
      .then((data) => {
        if (!plantLocation?.latitude && !plantLocation?.longitude) {
          return;
        }

        const line = turf.lineString([
          [+plantLocation.latitude, +plantLocation.longitude],
          ...data.map((shipper) => [+shipper.latitude, +shipper.longitude]),
        ]);

        if (!line) {
          return;
        }

        const bbox: BBox = turf.bbox(line);

        const { longitude, latitude, zoom } = new WebMercatorViewport(
          viewport
        ).fitBounds(
          [
            [bbox[1], bbox[0]],
            [bbox[3], bbox[2]],
          ],
          {
            padding: 20,
            offset: [0, -100],
          }
        );

        if (isZoom == false) {
          setIsZoom(true);
          setViewport({
            ...viewport,
            latitude,
            longitude,
            zoom,
          });
        }
      })
      .catch((error) => setIsZoom(true));
  }, [plantLocation?.latitude, plantLocation?.longitude, isZoom]);

  const handleChange = useCallback(
    (value: string) => {
      setUsersSelected([+value]);
      !!usersSelected[0] &&
        value &&
        getShippers({ userIds: usersSelected })
          .then((data) => {
            setLoading(true);

            setViewport({
              ...viewport,
              latitude: +data[0].latitude,
              longitude: +data[0].longitude,
            });
          })
          .catch((error) => console.log(`error`, error))
          .finally(() => setLoading(false));
      !value &&
        getPlantLocation(plantCode + '').then(() => {
          setIsZoom(false);
          handleGetAllShipper();
        });
    },
    [
      usersSelected,
      getShippers,
      getPlantLocation,
      plantCode,
      viewport,
      handleGetAllShipper,
    ]
  );

  useInterval(() => {
    usersSelected[0]
      ? getShippers({ userIds: usersSelected }).then((data) =>
          setViewport({
            ...viewport,
            latitude: +data[0]?.latitude,
            longitude: +data[0]?.longitude,
          })
        )
      : handleGetAllShipper();
  }, 3000);

  useEffect(() => {
    handleGetAllShipper();

    getShippers(myShippersParam)
      .then((data) =>
        setUserIdsOptions(
          data.map((item) => {
            return {
              userId: item.userId,
              userName: item.userName,
            };
          })
        )
      )
      .catch((error) => console.log(`error`, error));
  }, []);

  useEffect(() => {
    getPlantLocation(plantCode + '');
  }, [getPlantLocation, plantCode]);

  return (
    <MainLayout isLoading={loading}>
      <h6>Thông tin Geo Map</h6>
      Chọn shipper{' '}
      <Select
        placeholder="Chọn Shipper"
        onChange={handleChange}
        defaultValue="All Shippers"
        style={{ width: 200 }}
      >
        <Option value="">All Shippers</Option>

        {!!userIdsOptions?.length &&
          userIdsOptions?.map(({ userId, userName }) => (
            <Option key={userId} value={userId}>
              {userName}
            </Option>
          ))}
      </Select>
      <div className="mt-3">
        <ReactMapGL
          {...viewport}
          width="100%"
          height="80vh"
          onViewportChange={(nextViewport: IViewPort) => {
            setViewport(nextViewport);
          }}
          doubleClickZoom={true}
          goongApiAccessToken={GOONG_MAPTILES_KEY}
        >
          {plantLocation && (
            <Marker
              key={plantLocation.latitude}
              longitude={+plantLocation.longitude}
              latitude={+plantLocation.latitude}
            >
              <LocationIcon />
            </Marker>
          )}

          {!!shippersLocations.length &&
            shippersLocations.map((x) => (
              <>
                <Marker
                  key={x.userId}
                  longitude={+x.longitude}
                  latitude={+x.latitude}
                >
                  <p
                    className="absolute px-2 w-20 py-2 bg-white rounded-md bottom-20 "
                    hidden={!visible}
                    style={{ left: -30 }}
                  >
                    {`${x.userName}`}
                    <br />
                    {`NV-${x.userId}`}
                  </p>

                  <div
                    onClick={() => setVisible(!visible)}
                    className="px-2 py-2 bg-white rounded-full shadow-lg shadow-cyan-500/50"
                  >
                    <DefaultIconUser width={40} height={40} />
                  </div>

                  <div
                    className="absolute w-12 overflow-hidden inline-block"
                    style={{ bottom: -8.2, left: 21.8 }}
                  >
                    <div className=" p-1 bg-white -rotate-45 transform origin-top-left"></div>
                  </div>
                </Marker>
              </>
            ))}
        </ReactMapGL>
      </div>
    </MainLayout>
  );
}

export default GeoMapPage;
