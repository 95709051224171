import { Form, Input } from 'antd';
import Button from 'components/Button';

interface IProps {
  handleSearch: (values: any) => void;
}

export default function PermissionCodeFilter(props: IProps): JSX.Element {
  const { handleSearch } = props;

  const onFinish = (values: any) => {
    const params = {
      ...values,
    };
    Object.keys(params).forEach((k) => {
      if (params[k] == '') params[k] = undefined;
    });

    handleSearch(params);
  };

  return (
    <div className="flex justify-between items-center mt-8 mb-10">
      <Form
        layout="inline"
        className="items-center"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="prod_origin_code">
          <Input placeholder="Mã nguồn gốc" className="w-44" />
        </Form.Item>
        <Form.Item name="error_code">
          <Input placeholder="Mã Lỗi" className="w-44" />
        </Form.Item>
        <Form.Item name="email">
          <Input placeholder="Email" className="w-44" />
        </Form.Item>
        <Form.Item>
          <Button type="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
