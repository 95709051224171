import { Table } from 'antd';
import { NextIcon, PlayIcon } from 'assets/svg';
import { IPagingResponse } from 'interface/base.interface';
import { IShipment } from 'interface/shipment.interface';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import formatDateWithTime from 'utils/formatDateWithTime';
import { HocChangePagination } from 'utils/HocChangePagination';
import { translateShipmentStatus } from 'utils/translateStatus';
import { PauseIcon, StopIcon } from '../../../assets/svg';

interface IProps {
  dataSource?: IPagingResponse<IShipment>;
  onIconPress: (icon: string, shipmentCode: string) => void;
  page: number;
  page_size: number;
}

export default function ShipmentTable(props: IProps): JSX.Element {
  const { dataSource, onIconPress, page, page_size } = props;

  const history = useHistory();

  const handleNextIcon = (shipmentCode: string) => {
    history.push(`/shipment/${shipmentCode}`);
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, record: any) => (
          <span>{translateShipmentStatus(record.status)}</span>
        ),
      },
      {
        title: 'Mã shippment',
        dataIndex: 'shipment_code',
        key: 'shipment_code',
        render: (_: any, record: { shipment_code: string }) => (
          <p className="para-bold-2 text-primary-50">{record.shipment_code}</p>
        ),
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_: any, record: any) => (
          <span>{formatDateWithTime(record.createdAt)}</span>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'shipperEmail',
        key: 'shipperEmail',
      },
      {
        title: 'Họ và tên',
        dataIndex: 'shipperName',
        key: 'shipperName',
      },
      {
        title: 'Phương tiện',
        dataIndex: 'vehicleType',
        key: 'vehicleType',
      },
      {
        title: 'SDT Shipper',
        dataIndex: 'shipperPhone',
        key: 'shipperPhone',
      },
      {
        title: 'Shipment control',
        dataIndex: 'shipmentControl',
        key: 'shipmentControl',
        render: (_: any, record: IShipment) => (
          <div className="flex justify-center items-center">
            {[
              {
                icon: <PlayIcon />,
                onPress: () => onIconPress('continue', record.shipment_code),
                disable: record.status === 'active',
              },
              {
                icon: <PauseIcon />,
                onPress: () => onIconPress('pause', record.shipment_code),
                disable: record.status === 'pause',
              },
              {
                icon: <StopIcon />,
                onPress: () => onIconPress('stop', record.shipment_code),
                disable: record.status === 'stop',
              },
            ].map((x, index) => {
              const opacityAllButton =
                ['done', 'stop'].includes(record.status) && 0.5;

              const disableAllButton = ['done', 'stop'].includes(record.status);

              return (
                <button
                  className={`outline-none ml-2`}
                  key={index}
                  onClick={x.onPress}
                  disabled={disableAllButton || x.disable}
                  style={{
                    opacity: opacityAllButton || x.disable ? 0.5 : 1,
                  }}
                >
                  {x.icon}
                </button>
              );
            })}
          </div>
        ),
      },
      {
        title: 'Phê duyệt',
        dataIndex: 'approval',
        key: 'approval',
      },
      {
        title: 'Chi tiết GI',
        dataIndex: 'detail',
        key: 'detail',
        render: (_: any, record: IShipment) => (
          <div className="flex justify-center">
            <button className="outline-none">
              <NextIcon
                className="flex justify-center"
                onClick={() => handleNextIcon(record.shipment_code)}
              />
            </button>
          </div>
        ),
      },
    ];
  }, []);

  return (
    <Table
      className="customTable"
      dataSource={dataSource?.data}
      columns={columns}
      pagination={{
        current: page,
        pageSize: page_size,
        total: dataSource?.total,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        onChange: HocChangePagination(history),
      }}
    />
  );
}
