import { UserProvider } from 'contexts/User';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './route/Routes';
import { ReactQueryDevtools } from 'react-query/devtools';
import { UpdateDocumentTitle } from './utils/updateDocumentTitle';

// provide the default query function to your app with defaultOptions
const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <UpdateDocumentTitle />
          <Routes />
        </UserProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  );
}

export default App;
