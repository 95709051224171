/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

interface IProps {
  isEditing?: boolean;
  imagePath: string;
  handleFileChange: (file: File) => void;
}

export default function TransportImage(props: IProps): JSX.Element {
  const { isEditing, imagePath, handleFileChange } = props;
  const [file, setFile] = useState();
  const [imagePreview, setImagePreview] = useState<any>();

  const handleUploadImage = (e: any) => {
    setFile(e.target.files[0]);
    if (!e.target.files[0]) return;
    handleFileChange(e.target.files[0]);
  };

  useEffect(() => {
    if (!file) return;
    setImagePreview(URL.createObjectURL(file));
  }, [file]);

  return (
    <>
      {isEditing ? (
        <>
          <input
            type="file"
            id="image"
            className="hidden"
            accept=".jpeg, .png, .jpg"
            onChange={handleUploadImage}
          />
          <label htmlFor="image">
            <div
              className="relative h-8 w-8"
              style={{
                backgroundImage: `url(${
                  imagePreview ? imagePreview : imagePath && imagePath
                })`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            >
              <div className="absolute bg-neutral-90 opacity-30 hover:opacity-50 top-0 bottom-0 right-0 left-0 flex items-center justify-center cursor-pointer">
                <EditOutlined className="text-xl font-bold text-primary-0 hover:text-primary-20 " />
              </div>
            </div>
          </label>
        </>
      ) : (
        <div
          className="h-8 w-8"
          style={{
            backgroundImage: `url(${imagePath})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></div>
      )}
    </>
  );
}
