import {
  CheckCircleOutlined,
  FormOutlined,
  ProfileOutlined,
  StarFilled,
} from '@ant-design/icons';
import { PowerBi, UserEdit, UserManage, UserSetting } from 'assets/svg';
import { TMS_FUNC } from './function';

const date = new Date().toLocaleDateString('fr-CA');

const sidebarMenu = [
  {
    key: 'gi',
    title: 'Đơn vận',
    icon: FormOutlined,
    submenu: [
      {
        title: 'Tra cứu đơn vận',
        key: `/gi?toDate=${date}&fromDate=${date}`,
        active: TMS_FUNC.ADMIN_GET_GI,
      },
      {
        title: 'Điều chỉnh phiếu xuất',
        key: '/edit-gi',
        active: TMS_FUNC.ADMIN_ACTIVE_GI,
      },
      {
        title: 'Thông tin chi tiết tra cứu vận đơn',
        key: `/gi-detail?toDate=${date}&fromDate=${date}`,
        active: TMS_FUNC.ADMIN_GET_GI,
      },
    ],
    active: [TMS_FUNC.ADMIN_GET_GI, TMS_FUNC.ADMIN_ACTIVE_GI],
  },
  {
    key: '/shipment',
    title: 'Chuyến hàng',
    icon: ProfileOutlined,
    active: [TMS_FUNC.ADMIN_GET_SHIPMENTS],
  },
  {
    key: 'plantRoute',
    title: 'Plant & Tuyến đường',
    icon: CheckCircleOutlined,
    active: [TMS_FUNC.ADMIN_GET_ROUTES, TMS_FUNC.ADMIN_GET_PLANTS],
    submenu: [
      {
        title: 'Quản lý Plant',
        key: '/plant',
        active: TMS_FUNC.ADMIN_GET_PLANTS,
      },
      {
        title: 'Quản lý tuyến đường',
        key: '/route',
        active: TMS_FUNC.ADMIN_GET_ROUTES,
      },
    ],
  },
  {
    key: 'user',
    title: 'Người dùng & Phân quyền',
    icon: UserSetting,
    active: [
      TMS_FUNC.ADMIN_GET_ACCESS_CONTROLS,
      TMS_FUNC.ADMIN_GET_GROUPS,
      TMS_FUNC.ADMIN_GET_FUNCTIONS,
      TMS_FUNC.ADMIN_GET_JOB_TITLES,
      TMS_FUNC.ADMIN_GET_MAPPING_JOB_TITLES,
    ],
    submenu: [
      {
        title: 'Phân quyền',
        key: '/role-based-auth',
        active: TMS_FUNC.ADMIN_GET_ACCESS_CONTROLS,
      },
      {
        title: 'Nhóm người dùng',
        key: '/groups',
        active: TMS_FUNC.ADMIN_GET_GROUPS,
      },
      {
        title: 'Quyền',
        key: '/functions',
        active: TMS_FUNC.ADMIN_GET_FUNCTIONS,
      },
      {
        title: 'Mã chức vụ',
        key: '/job-title',
        active: TMS_FUNC.ADMIN_GET_JOB_TITLES,
      },
      {
        title: 'Gán nhóm tự động',
        key: '/mapping-job-title',
        active: TMS_FUNC.ADMIN_GET_MAPPING_JOB_TITLES,
      },
    ],
  },
  // {
  //   key: '/transport-management',
  //   title: 'Quản lý PTGT',
  //   icon: ProfileOutlined,
  //   active: [TMS_FUNC.ADMIN_GET_VEHICLES],
  // },
  // {
  //   key: '/geo-map',
  //   title: 'Báo cáo',
  //   icon: PowerBi,
  //   active: [TMS_FUNC.ADMIN_GET_GEO_MAP],
  // },
  {
    key: 'client',
    title: 'Quản lý Clients',
    icon: UserEdit,
    active: [TMS_FUNC.ADMIN_GET_CLIENTS],
    submenu: [
      {
        key: '/clients-management',
        title: 'Danh sách clients',
        active: TMS_FUNC.ADMIN_GET_CLIENTS,
      },
      {
        key: '/clients-management/new-client',
        title: 'Tạo mới clients',
        active: TMS_FUNC.ADMIN_GET_CLIENTS,
      },
      // {
      //   key: '/clients-management/edit-client',
      //   title: 'Chỉnh sửa Clients',
      // },
    ],
  },
  // {
  //   key: 'shipper',
  //   title: 'Shipper',
  //   icon: ProfileOutlined,
  //   active: [TMS_FUNC.ADMIN_GET_VEHICLES],
  //   submenu: [
  //     {
  //       key: '/transport-management',
  //       title: 'Quản lý PTGT',
  //       active: TMS_FUNC.ADMIN_GET_VEHICLES,
  //     },
  //   ],
  // },
  {
    key: 'report',
    title: 'Báo cáo',
    icon: PowerBi,
    active: [TMS_FUNC.ADMIN_GET_GEO_MAP],
    submenu: [
      {
        key: '/geo-map',
        title: 'Geo map',
        active: TMS_FUNC.ADMIN_GET_GEO_MAP,
      },
      {
        key: '/cockpit',
        title: 'Bảng so sánh Cockpit',
        active: TMS_FUNC.ADMIN_GET_REPORT_COCKPIT,
      },
      {
        key: '/goods',
        title: 'Báo cáo hàng đi đường',
        active: TMS_FUNC.ADMIN_GET_REPORT_GOODS,
      },
      {
        key: '/masterdata',
        title: 'Báo cáo Master Data GI',
        active: TMS_FUNC.ADMIN_GET_REPORT_MASTERDATA,
      },
      {
        key: '/quality',
        title: 'Báo cáo KPI',
        active: TMS_FUNC.ADMIN_GET_REPORT_QUALITY,
      },
      {
        key: '/kpi',
        title: 'Báo cáo theo dõi',
        active: TMS_FUNC.ADMIN_GET_REPORT_KPI,
      },
      {
        key: '/top-performer',
        title: 'Báo cáo Top Performer',
        active: TMS_FUNC.ADMIN_GET_REPORT_TOP_PERFORMER,
      },
      {
        key: '/master-error',
        title: 'Báo cáo Master PHHH',
        active: TMS_FUNC.ADMIN_GET_REPORT_MASTER_ERROR,
      },
      {
        key: '/error-code',
        title: 'Báo cáo PHHH',
        active: TMS_FUNC.ADMIN_GET_REPORT_ERROR_CODE,
      },
      // {
      //   key: '/clients-management/edit-client',
      //   title: 'Chỉnh sửa Clients',
      // },
    ],
  },
  {
    key: 'rating',
    title: 'Rating - Feedback',
    icon: StarFilled,
    active: [TMS_FUNC.ADMIN_GET_RATING],
    submenu: [
      {
        key: '/rating',
        title: 'Danh sách đánh giá - phản hồi',
        active: TMS_FUNC.ADMIN_GET_RATING,
      },
      {
        key: '/master-code',
        title: 'Danh sách mã lỗi',
        active: TMS_FUNC.ADMIN_GET_RATING,
      },
      {
        key: '/permission-code',
        title: 'Quản lý quyền mã lỗi',
        active: TMS_FUNC.ADMIN_GET_RATING,
      },
    ],
  },
  //   {
  //     key: 'billlading',
  //     title: 'Quản lý vận đơn',
  //     icon: FormOutlined,
  //     submenu: [
  //       {
  //         title: 'Danh sách vận đơn',
  //         key: '/bill-of-lading',
  //       },
  //     ],
  //   },
];

export default sidebarMenu;
