import { Divider, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { BoxIcon, PrinterIcon } from 'assets/svg';
import Button from 'components/Button';
import { GIDetails, IGi, IGiDetail } from 'interface/gi.interface';
import formatDateWithTime from 'utils/formatDateWithTime';
import { translateGIStatus } from 'utils/translateStatus';

function TextRow(props: { label: string; value: string }): JSX.Element {
  const { label, value } = props;

  return (
    <div className="flex justify-between items-center mb-4">
      <div className="para-2">{label}</div>
      <div className="para-bold-2">{value}</div>
    </div>
  );
}

function GiDetailBody(props: {
  gi: IGiDetail;
  onCancel: () => void;
}): JSX.Element {
  const { gi, onCancel } = props;
  const { status, document, createdby } = gi;

  const columns = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'material',
      render: (_: any, record: GIDetails) => (
        <p className="para-bold-2 text-primary-50">{record.material}</p>
      ),
    },
    { title: 'Số batch', dataIndex: 'batch' },
    { title: 'Số lượng', dataIndex: 'quantity' },
    { title: 'Đơn vị tính', dataIndex: 'uom' },
  ];

  return (
    <div className="bg-white rounded-2.5xl p-2">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="bg-primary-90 flex justify-center items-center rounded-2xl p-3">
            <BoxIcon />
          </div>
          <div className="ml-5">
            <p className="para-bold-1 mb-1">{translateGIStatus(status)}</p>
            <p className="para-3">{document}</p>
          </div>
        </div>

        <button
          className="rounded-full bg-primary-40 bg-opacity-10 p-2"
          onClick={() => window.open(gi.einvoice, '_blank')}
        >
          <PrinterIcon />
        </button>
      </div>

      <Divider />

      <div>
        <TextRow label="Đơn vị giao" value={gi.deliveryUnit.unit} />
        <TextRow label="Địa chỉ" value={gi.deliveryUnit.fullAddress} />
        <TextRow label="Người tạo phiếu xuất" value={createdby} />
        <TextRow
          label="Ngày xuất kho"
          value={formatDateWithTime(gi.createdAt)}
        />
        <TextRow label="Đơn vị nhận" value={gi.receivingUnit.unit} />
        <TextRow label="Địa chỉ" value={gi.receivingUnit.fullAddress} />
      </div>

      <Divider />

      <div className="para-bold-2 mb-3">Danh sách sản phẩm</div>

      <Table
        rowKey={(record) => record.id}
        dataSource={gi.giDetails}
        columns={columns}
        pagination={false}
        className="mb-5"
      />

      <div className="flex justify-center">
        <Button size="md" onClick={onCancel}>
          Đóng
        </Button>
      </div>
    </div>
  );
}

interface IProps {
  gi: IGiDetail;
  isVisible: boolean;
  onCancel: () => void;
}

export default function GIDetailModal(props: IProps): JSX.Element {
  const { isVisible, onCancel, gi } = props;

  return (
    <Modal
      centered
      visible={isVisible}
      footer={null}
      closable={false}
      onCancel={onCancel}
      width={569}
      className="rounded-2.5xl"
      wrapClassName="customModal"
    >
      <GiDetailBody gi={gi} onCancel={onCancel} />
    </Modal>
  );
}
