import axiosClient from './axiosClient';
import {
  IPagination,
  IPagingResponse,
  IResponse,
} from 'interface/base.interface';
import {
  IAssignFunction,
  ICreateGroup,
  ICreateJobTitle,
  ICreateMappingJobTitle,
  IFunction,
  IGroup,
  IGroupDetails,
  IInsertDefaultFunction,
  IJobTitle,
  IJobTitleQuery,
  IMappingJobTitle,
  IMappingJobTitleQuery,
} from 'interface/permission.interface';
const path = 'abe/permission';

const PermissionApi = {
  getFunctions: (params?: IPagination): Promise<IPagingResponse<IFunction>> => {
    return axiosClient.get(`/${path}/function`, { params });
  },
  getGroups: (
    params?: IPagination & { name?: string }
  ): Promise<IPagingResponse<IGroup>> => {
    return axiosClient.get(`/${path}/group`, { params });
  },
  getGroup: (groupName?: string): Promise<IGroupDetails> => {
    return axiosClient.get(`/${path}/group/${groupName}`);
  },
  createGroup: (data: ICreateGroup): Promise<IResponse> => {
    return axiosClient.post(`/${path}/group`, data);
  },
  assignFunction: ({ refId, ...data }: IAssignFunction): Promise<IResponse> => {
    return axiosClient.post(`/${path}/user/${refId}/function`, data);
  },
  insertDefaultFunction: ({
    refGroup,
    ...data
  }: IInsertDefaultFunction): Promise<IResponse> => {
    return axiosClient.post(`/${path}/group/${refGroup}/function`, data);
  },
  queryJobTitle: (
    params?: IJobTitleQuery
  ): Promise<IPagingResponse<IJobTitle>> => {
    return axiosClient.get(`/${path}/job-title`, { params });
  },
  mutationJobTitle: (data: ICreateJobTitle) => {
    return axiosClient.post(`/${path}/job-title`, data);
  },
  queryMappingJobTitle: (
    params: IMappingJobTitleQuery
  ): Promise<IPagingResponse<IMappingJobTitle>> => {
    return axiosClient.get(`/${path}/mapping-job-title`, { params });
  },
  mutaionMappingJobTitle: (data: ICreateMappingJobTitle): Promise<Response> => {
    return axiosClient.post(`/${path}/mapping-job-title`, data);
  },
  assignUserGroups: (data: { userId: number; groups: string[] }) => {
    return axiosClient.post(`/${path}/assign/groups`, data);
  },
};

export default PermissionApi;
