/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd';
import AuthApi from 'apis/AuthApi';
import { IUser } from 'interface/user.interface';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { getLogoutUri } from '../utils/getLogouURI';

type UserContextValues = {
  user?: IUser;
  getUser: () => void;
  signOut: () => void;
};

const UserContext = createContext<UserContextValues>(undefined as never);

const useUser = (): UserContextValues => useContext(UserContext);

const UserProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [user, setUser] = useState<IUser>();

  const handleRemoveToken = () => {
    localStorage.removeItem('token');

    const logout_uri = getLogoutUri();

    window.location.replace(logout_uri);
  };

  const { mutate: getUser, isLoading } = useMutation(AuthApi.getMe, {
    onSuccess: (response) => {
      setUser(response);
    },
    onError: (error) => {
      handleRemoveToken();
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;
    getUser();
  }, []);

  const signOut = () => {
    // Remove token from localStorage
    handleRemoveToken();
  };

  return (
    <UserContext.Provider value={{ user, getUser, signOut }}>
      <Spin size="large" spinning={isLoading}>
        {children}
      </Spin>
    </UserContext.Provider>
  );
};

export { useUser, UserProvider };
