import { Divider, Form, Input, Select } from 'antd';
import ShipmentApi from 'apis/ShipmentApi';
import { PR_Monochromatic } from 'assets/images';
import { MagifyingGlassIcon } from 'assets/svg';
import { MainLayout } from 'components';
import Button from 'components/Button';
import ModalBase from 'components/ModalBase';
import ShipmentTable from 'components/Modules/Shipment/ShipmentTable';
import { useUser } from 'contexts/User';
import useQueryParam from 'hooks/useQueryPrams';
import {
  IControlShipmentAction,
  IGetShipmentsQuery,
} from 'interface/shipment.interface';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, shipmentStates } from 'utils/constants';
import handleError401 from 'utils/handleError401';
import { openNotificationWithIcon } from 'utils/notification';
import { renderObjectOptions } from 'utils/renderOptions';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';

export default function ShipmentManagementPage(): JSX.Element {
  const { user } = useUser();
  const history = useHistory();

  const queryParam = useQueryParam();

  const keyword = queryParam.get('search') || undefined;
  const email = queryParam.get('email') || undefined;
  const status = queryParam.get('status') || undefined;
  const vehicleType = queryParam.get('vehicleType') || undefined;
  const fullName = queryParam.get('fullName') || undefined;
  const page = parseInt(queryParam.get('page') + '') || 1;
  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const [isVisible, setIsvisible] = useState<boolean>(false);
  const [context, setContext] = useState<{
    action: IControlShipmentAction;
    contextContent: string;
    shipmentCode: string;
  }>();

  const shipmentParams = {
    page,
    page_size,
    keyword,
    email,
    status,
    vehicleType,
    fullName,
  };

  const params = JSON.parse(JSON.stringify(shipmentParams));

  const {
    data: shipments,
    isLoading: isLoaingShipment,
    refetch: refetchShipment,
  } = useQuery(['getShipments', shipmentParams], () =>
    ShipmentApi.getShipments(params)
  );

  const { mutate: controlShipment, isLoading: isLoadingControlerShipment } =
    useMutation(ShipmentApi.controlShipment, {
      onSuccess: () => {
        refetchShipment();
        setIsvisible(false);
        openNotificationWithIcon('success', 'Cập nhật chuyến hàng thành công');
      },
      onError: (error: any) => {
        console.log(`error control shipment`);
        setIsvisible(false);
        openNotificationWithIcon('error', error.response.data.message[0]);
        if (error.response.data.status === 401) {
          handleError401();
        }
      },
    });

  const onFinish = (data: any) => {
    const shipmentParams: Partial<IGetShipmentsQuery> = JSON.parse(
      JSON.stringify({
        ...data,
        page: 1,
        page_size,
      })
    );
    history.push({
      pathname: location.pathname,
      search: updateQueryStringParameter(location.search, shipmentParams),
    });
  };

  const handleIconPress = (icon: string, shipmentCode: string) => {
    setIsvisible(true);

    switch (icon) {
      case 'continue':
        setContext({
          action: icon,
          contextContent: 'Bạn muốn chuyến hàng này tiếp tục tiến hành?',
          shipmentCode,
        });
        break;

      case 'pause':
        setContext({
          action: icon,
          contextContent: 'Bạn muốn tạm dừng chuyến hàng này?',
          shipmentCode,
        });
        break;

      case 'stop':
        setContext({
          action: icon,
          contextContent: 'Bạn muốn chuyến hàng này bị thu hồi? ',
          shipmentCode,
        });
        break;

      default:
        break;
    }
  };

  return (
    <MainLayout isLoading={isLoaingShipment || isLoadingControlerShipment}>
      <p className="para-2 text-primary-0 opacity-45 mb-1">
        Xin chào {user?.fullName}
      </p>

      <h6 className="mb-8">Thông tin chuyến hàng</h6>

      <Form layout="inline" className="items-center mb-8" onFinish={onFinish}>
        <Form.Item name="search">
          <Input
            placeholder="Tìm kiếm"
            suffix={<MagifyingGlassIcon />}
            defaultValue={keyword}
          />
        </Form.Item>

        <Form.Item name="status">
          <Select placeholder="Trạng thái" defaultValue={status}>
            {renderObjectOptions(shipmentStates)}
          </Select>
        </Form.Item>

        <Form.Item name="email">
          <Input placeholder="Email" defaultValue={email} />
        </Form.Item>

        <Form.Item name="fullName">
          <Input placeholder="Họ và tên" defaultValue={fullName} />
        </Form.Item>

        <Form.Item name="vehicleType">
          <Input placeholder="Phương tiện" defaultValue={vehicleType} />
        </Form.Item>

        <Form.Item>
          <Button type="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>

      <ShipmentTable
        dataSource={shipments}
        onIconPress={handleIconPress}
        page={page}
        page_size={page_size}
      />

      <ModalBase isVisible={isVisible} onCancel={() => setIsvisible(false)}>
        <div className="flex flex-col h-full items-center justify-center">
          <img
            src={PR_Monochromatic}
            alt="PR_Monochromatic"
            className="cursor-pointer"
          />
          <p className="para-bold-2 text-primary-40 my-4">Thông báo!</p>

          <p className="para-2 text-primary-40">{context?.contextContent}</p>

          <Divider />
          <div className="flex justify-between">
            <Button
              size="md"
              onClick={() => setIsvisible(false)}
              color="neutral-60"
              bgColor="transparent"
            >
              Hủy
            </Button>
            <Button
              size="md"
              onClick={() =>
                controlShipment({
                  shipmentCode: context?.shipmentCode || '',
                  action: context?.action || 'continue',
                })
              }
            >
              Đồng ý
            </Button>
          </div>
        </div>
      </ModalBase>
    </MainLayout>
  );
}
