import {
  CreateOauthClient,
  EditOauthClient,
  IOauthClientDetail,
  OauthClient,
} from 'interface/oauth_client';
import axiosClient from './axiosClient';

const OauthClientAPI = {
  getClients: async (): Promise<OauthClient[]> => {
    return await axiosClient.get('/abe/client');
  },
  getClient: (id: string): Promise<IOauthClientDetail> => {
    return axiosClient.get(`/abe/client/${id}`);
  },
  newClient: (data: CreateOauthClient): Promise<IOauthClientDetail> => {
    return axiosClient.post('/abe/client', data);
  },
  editClient: (data: EditOauthClient): Promise<IOauthClientDetail> => {
    return axiosClient.put('/abe/client', data);
  },
};

export default OauthClientAPI;
