import { Col, Divider, Row, Typography } from 'antd';
import MainLayout from 'components/Modules/Layout/MainLayout';
import StatiscicalCard from 'components/Modules/Layout/StatisticalCard';

const { Text } = Typography;

const HomePage = (): JSX.Element => {
  return (
    <MainLayout>
      {/* <Row gutter={16}>
        <Col span={6}>
          <StatiscicalCard title="Total Sales" statisticalNumber="¥ 126,560">
            <Text>WoW Change 12%</Text>
            <br />
            <Text>DoD Change 11%</Text>
            <Divider />
            <div className="flex items-center">
              <Text>Daily Sales</Text>
              <Text className="ml-2"> ￥12,423</Text>
            </div>
          </StatiscicalCard>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <StatiscicalCard title="Total Sales" statisticalNumber="¥ 126,560">
            <Text>WoW Change 12%</Text>
            <br />
            <Text>DoD Change 11%</Text>
            <Divider />
            <div className="flex items-center">
              <Text>Daily Sales</Text>
              <Text className="ml-2"> ￥12,423</Text>
            </div>
          </StatiscicalCard>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <StatiscicalCard title="Total Sales" statisticalNumber="¥ 126,560">
            <Text>WoW Change 12%</Text>
            <br />
            <Text>DoD Change 11%</Text>
            <Divider />
            <div className="flex items-center">
              <Text>Daily Sales</Text>
              <Text className="ml-2"> ￥12,423</Text>
            </div>
          </StatiscicalCard>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <StatiscicalCard title="Total Sales" statisticalNumber="¥ 126,560">
            <Text>WoW Change 12%</Text>
            <br />
            <Text>DoD Change 11%</Text>
            <Divider />
            <div className="flex items-center">
              <Text>Daily Sales</Text>
              <Text className="ml-2"> ￥12,423</Text>
            </div>
          </StatiscicalCard>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <StatiscicalCard title="Total Sales" statisticalNumber="¥ 126,560">
            <Text>WoW Change 12%</Text>
            <br />
            <Text>DoD Change 11%</Text>
            <Divider />
            <div className="flex items-center">
              <Text>Daily Sales</Text>
              <Text className="ml-2"> ￥12,423</Text>
            </div>
          </StatiscicalCard>
        </Col>
      </Row> */}
    </MainLayout>
  );
};

export default HomePage;
