import {
  BillOfLadingFilterForm,
  BillOfLadingTable,
  MainLayout,
} from 'components';

//mocks data
const mocks = [
  {
    id: '1',
    date: '20/11/2021',
    shipper: 'Hieu Dinh',
    total: 18,
    finished: 17,
    unfinished: 1,
  },
  {
    id: '2',
    date: '20/11/2021',
    shipper: 'Oliver',
    total: 2,
    finished: 2,
    unfinished: 2,
  },
];

export default function BillOfLadingManagementPage(): JSX.Element {
  const handleFilter = (values: any) => {
    console.log(values);
  };

  return (
    <MainLayout>
      <BillOfLadingFilterForm onFinish={handleFilter} />

      <BillOfLadingTable dataSource={mocks} />
    </MainLayout>
  );
}
