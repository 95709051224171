/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IBearer,
  IPowerBiReport,
  IPowerBiReportToken,
  IReport,
} from 'interface/powerbi.interface';
import axiosClient from './axiosClient';

const PowerBiApi = {
  getAccessBearer: async (): Promise<IBearer> => {
    return await axiosClient.get('/abe/powerbi');
  },
  getReportInfo: async (report: IReport): Promise<IPowerBiReport> => {
    //const token = await MicrosoftAuth.getAccessBearer();
    const token = '';
    return await axiosClient.get(
      `/groups/${report.groupId}/reports/${report.reportId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getToken: async (
    report: IReport,
    reportInfo?: IPowerBiReport
  ): Promise<IPowerBiReportToken> => {
    const token = '';
    return await axiosClient.post(
      `/groups/${report.groupId}/reports/${report.reportId}/GenerateToken`,
      {
        params: {
          datasets: [{ id: reportInfo?.datasetId }],
          reports: [{ id: reportInfo?.id }],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
};

export default PowerBiApi;
