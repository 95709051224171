/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Table } from 'antd';
import PermisstionApi from 'apis/PermissionApi';
import { NextIcon } from 'assets/svg';
import { MainLayout } from 'components';
import useQueryParam from 'hooks/useQueryPrams';
import {
  ICreateGroup,
  IFunction,
  IGroup,
} from 'interface/permission.interface';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { openNotificationWithIcon } from 'utils/notification';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';

const columnsFunctions = [
  {
    title: 'Chức năng chưa có trong Group',
    dataIndex: 'name',
    key: 'name',
  },
];

const columnsFunctionsExisted = [
  {
    title: 'Chức năng đã có trong Group',
    dataIndex: 'name',
    key: 'name',
  },
];

const GroupsPage = (): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [visible, setVisible] = useState<boolean>(false);

  const [functionsNotExisted, setFunctionsNotExisted] = useState<IFunction[]>(
    []
  );
  const [functionsExisted, setFunctionsExisted] = useState<IFunction[]>([]);

  const [groupName, setGroupName] = useState<string>('');

  const groupParams = {
    page: parseInt(useQueryParam().get('page') + '') || 1,
    page_size: 12,
    name: useQueryParam().get('name') || '',
  };

  const params = JSON.parse(JSON.stringify(groupParams));

  const columns = useMemo(() => {
    return [
      {
        title: 'Tên nhóm',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Tiêu đề',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Chỉnh sửa chức năng trong Nhóm',
        dataIndex: 'detail',
        key: 'detail',

        render: (_: any, record: IGroup) => (
          <div className="flex justify-center">
            <button className="outline-none">
              <NextIcon
                className="flex justify-center"
                onClick={() => handleNextIcon(record.name)}
              />
            </button>
          </div>
        ),
      },
    ];
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setVisible(false);
  };

  const { mutate: createGroup } = useMutation(PermisstionApi.createGroup, {
    onSuccess: () => {
      openNotificationWithIcon('success', 'Tạo thành công');
      groupRefetch();
      handleCancel();
    },
    onError: () => {
      openNotificationWithIcon('error', 'Lỗi');
    },
  });

  const onFinish = (values: ICreateGroup) => {
    createGroup(values);
  };

  const onSearch = (value: { name: string }) => {
    const search: Partial<{ name: string }> = JSON.parse(
      JSON.stringify({
        ...value,
        page: 1,
        page_size: 10,
      })
    );

    history.push({
      pathname: location.pathname,
      search: updateQueryStringParameter(location.search, search),
    });
  };

  const {
    data: result,
    isLoading,
    refetch: groupRefetch,
  } = useQuery(['getGroups', params], () => PermisstionApi.getGroups(params));

  const { mutate: getGroupDetails } = useMutation(PermisstionApi.getGroup, {
    onSuccess: (data) => {
      setFunctionsNotExisted(data.functionNotInGroup);
      setFunctionsExisted(data.funtionInGroup);
    },
    onError: () => {
      openNotificationWithIcon('error', 'Lỗi');
    },
  });

  const { mutate: insertDefaultFunction } = useMutation(
    PermisstionApi.insertDefaultFunction,
    {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Chỉnh sủa thành công');
        groupRefetch();
        // handleCancel();
        getGroupDetails(groupName);
      },
      onError: () => {
        openNotificationWithIcon('error', 'Lỗi');
      },
    }
  );

  const handleNextIcon = (groupName: string) => {
    setVisible(true);
    getGroupDetails(groupName);
    setGroupName(groupName);
  };

  return (
    <MainLayout>
      <div className="flex justify-between">
        <div>
          <Form
            layout="inline"
            className="items-center"
            onFinish={onSearch}
            autoComplete="off"
          >
            <Form.Item name="name">
              <Input placeholder="Tìm kiếm theo Tên nhóm" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Form.Item>
          </Form>
        </div>

        <Button type="primary" onClick={showModal}>
          Tạo nhóm
        </Button>
      </div>

      <Modal
        title="Tạo nhóm mới"
        visible={isModalVisible}
        footer={null}
        closeIcon={<CloseOutlined onClick={handleCancel} />}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
        >
          <Form.Item
            label="Tên nhóm"
            name="name"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Mô tả"
            name="description"
            rules={[
              { required: true, message: 'Please input your description!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Tiêu đề"
            name="title"
            rules={[{ required: true, message: 'Please input your title!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Tạo
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`Chỉnh sửa Chức năng trong nhóm ${groupName}`}
        visible={visible}
        footer={null}
        closeIcon={<CloseOutlined onClick={handleCancel} />}
        width={1050}
      >
        <div className="flex">
          <div>
            <Table
              columns={columnsFunctions}
              dataSource={functionsNotExisted}
              pagination={false}
              scroll={{ y: 240 }}
              rowKey={(record) => record.name}
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    event.preventDefault();
                    insertDefaultFunction({
                      refFunction: record.name,
                      refGroup: groupName,
                      action: 'insert',
                    });
                  },
                };
              }}
            />
          </div>
          <div className="border-2 border-neutral-40 mx-2"></div>
          <div>
            <Table
              columns={columnsFunctionsExisted}
              dataSource={functionsExisted}
              pagination={false}
              scroll={{ y: 240 }}
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    insertDefaultFunction({
                      refFunction: record.name,
                      refGroup: groupName,
                      action: 'remove',
                    });
                    event.preventDefault();
                  },
                };
              }}
            />
          </div>
        </div>
      </Modal>

      <Table
        dataSource={result?.data}
        columns={columns}
        loading={isLoading}
        className="customTable"
        rowKey="name"
        pagination={{
          total: result?.total,
          pageSize: groupParams.page_size,
          defaultCurrent: groupParams.page,
          defaultPageSize: groupParams.page_size,
          onChange: (current) => {
            history.push({
              pathname: location.pathname,
              search: updateQueryStringParameter(location.search, {
                page: current,
              }),
            });
          },
        }}
      />
    </MainLayout>
  );
};

export default GroupsPage;
