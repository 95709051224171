import { CloseOutlined } from '@ant-design/icons';
import { Table, Button, Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import PermissionApi from 'apis/PermissionApi';
import { NextIcon } from 'assets/svg';
import { MainLayout } from 'components';
import useQueryParam from 'hooks/useQueryPrams';
import { IFunction } from 'interface/permission.interface';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';

const FunctionPage = () => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const functionParams = {
    page: parseInt(useQueryParam().get('page') + '') || 1,
    page_size: 12,
  };

  const params = JSON.parse(JSON.stringify(functionParams));

  const {
    data: result,
    isLoading,
    refetch,
  } = useQuery(['getFunctions', params], () =>
    PermissionApi.getFunctions(params)
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Tên chức năng',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Tiêu đề',
        dataIndex: 'title',
        key: 'title',
      },
    ];
  }, []);

  return (
    <MainLayout isLoading={isLoading}>
      <Table
        className="customTable"
        dataSource={result?.data}
        columns={columns}
        pagination={{
          total: result?.total,
          pageSize: functionParams.page_size,
          defaultCurrent: functionParams.page,
          defaultPageSize: functionParams.page_size,
          onChange: (current) => {
            history.push({
              pathname: location.pathname,
              search: updateQueryStringParameter(location.search, {
                page: current,
              }),
            });
          },
        }}
      />
    </MainLayout>
  );
};

export default FunctionPage;
