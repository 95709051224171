import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import { IGroup, IJobTitle } from 'interface/permission.interface';

interface IProps {
  isModalVisible: boolean;
  onCancel: () => void;
  mutationUser: (data: any) => void;
  groups?: IGroup[];
  jobTitles?: IJobTitle[];
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

export default function CreateMappingJobTitleModal(props: IProps): JSX.Element {
  const { isModalVisible, onCancel, mutationUser, groups, jobTitles } = props;

  const [form] = Form.useForm();

  // useEffect(() => {
  //   form.resetFields();
  // }, [jobTitles]);

  return (
    <Modal
      title="Tạo mới Mapping Job Title"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        name="create_mapping_job_title"
        onFinish={mutationUser}
        className="bg-white p-2 gap-4 lg:w-90 md:w-90 sm:w-100"
        {...formLayout}
      >
        <Form.Item label="Mã code" name="jobTitleCode">
          {/* <Input name="jobTitleCode" placeholder="PNJ.CODE.123" /> */}
          <Select placeholder="Chọn Job Title">
            {jobTitles?.map((group) => (
              <Option value={group.jobTitleCode} key={group.jobTitleCode}>
                {group.jobTitle}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Chức Vụ" name="groupName">
          <Select placeholder="Chọn group">
            {groups?.map((group) => (
              <Option value={group.name} key={group.name}>
                {group.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Mô tả" name="description">
          <Input name="description" placeholder="some description" />
        </Form.Item>

        <Form.Item label="Hoạt Động" name="active" valuePropName="checked">
          <Checkbox name="active" />
        </Form.Item>

        <Form.Item className="flex items-center justify-center">
          <Button type="primary" htmlType="submit" className="bg-primary">
            Tạo
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
