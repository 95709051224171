export const translateGIStatus = (status: string) => {
  switch (status) {
    case 'waiting':
      return 'Chờ xuất kho';
    case 'pending':
      return 'Chờ vận chuyển';
    case 'preparing':
      return 'Đang vận chuyển';
    case 'delivering':
      return 'Đang được giao';
    case 'completed':
      return 'Đã được giao';
    case 'returning':
      return 'Đang trả';
    case 'cancelled':
      return 'Đã hủy';
    case 'done':
      return 'Đã nhập kho';

    default:
      break;
  }
};

export const translateShipmentStatus = (status: string) => {
  switch (status) {
    case 'active':
      return 'Đang hoạt động';
    case 'done':
      return 'Hoàn thành';
    case 'pause':
      return 'Tạm dừng';
    case 'stop':
      return 'Thu hồi';

    default:
      break;
  }
};

export const translateRatingStatus = (status: string) => {
  switch (status) {
    case 'done':
      return 'Đã đánh giá';
    case 'expried':
      return 'Quá hạn đánh giá';
    case 'waiting':
      return 'Chờ đánh giá';
    case 'process':
      return 'Đang đánh giá';
  }
};

export const translateRatingGiStatusPHHH = (gi_status_phhh: string) => {
  switch (gi_status_phhh) {
    case 'send':
      return 'Đã gửi phản hồi';
    case 'unsent':
      return 'Chưa gửi phản hồi';
    case 'reject':
      return 'Từ chối phản hồi';
  }
};

export const translateRatingStatusAction = (status: string) => {
  switch (status) {
    case 'done':
      return 'Xem chi tiết';
    case 'expried':
      return 'Thay đổi trạng thái';
  }
};
