export enum TMS_FUNC {
  ADMIN_GET_GI = 'admin_get_gi',
  ADMIN_ACTIVE_GI = 'admin_active_gi',
  ADMIN_GET_SHIPMENTS = 'admin_get_shipments',
  ADMIN_GET_ROUTES = 'admin_get_routes',
  ADMIN_GET_PLANTS = 'admin_get_plants',
  ADMIN_GET_GROUPS = 'admin_get_groups',
  ADMIN_GET_FUNCTIONS = 'admin_get_functions',
  ADMIN_GET_JOB_TITLES = 'admin_get_job_titles',
  ADMIN_GET_MAPPING_JOB_TITLES = 'admin_get_mapping_job_titles',
  ADMIN_GET_ACCESS_CONTROLS = 'admin_get_access_controls',
  ADMIN_GET_CLIENTS = 'admin_get_clients',
  ADMIN_GET_VEHICLES = 'admin_get_vehicles',
  ADMIN_GET_GEO_MAP = 'admin_get_geomap',
  ADMIN_GET_REPORT_COCKPIT = 'admin_get_report_cockpit',
  ADMIN_GET_REPORT_GOODS = 'admin_get_report_goods',
  ADMIN_GET_REPORT_MASTERDATA = 'admin_get_report_masterdata',
  ADMIN_GET_REPORT_QUALITY = 'admin_get_report_quality',
  ADMIN_GET_REPORT_KPI = 'admin_get_report_kpi',
  ADMIN_GET_REPORT_TOP_PERFORMER = 'admin_get_report_top_performer',
  ADMIN_GET_REPORT_MASTER_ERROR = 'admin_get_report_master_error',
  ADMIN_GET_REPORT_ERROR_CODE = 'admin_get_report_error_code',
  ADMIN_GET_RATING = 'admin_get_rating',
}
