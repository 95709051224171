import ShipmentApi from 'apis/ShipmentApi';
import { MainLayout } from 'components';
import CommonBreadcrumb from 'components/CommonBreadcrumb';
import GIShipmentTable from 'components/Modules/Shipment/GIShimentTable';
import useQueryParam from 'hooks/useQueryPrams';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

export default function ShipmentDetailPage(): JSX.Element {
  const queryParam = useQueryParam();
  const page = parseInt(queryParam.get('page') + '') || 1;
  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const { shipmentCode } = useParams<{ shipmentCode: string }>();
  const shipmentParams = { page, page_size, shipmentCode };
  const params = JSON.parse(JSON.stringify(shipmentParams));

  const {
    data: shipmentDetail,
    refetch: refetchShipmentDetail,
    isLoading,
  } = useQuery(['getShipmentDetail', shipmentParams], () =>
    ShipmentApi.getShipmentDetail(params)
  );

  return (
    <MainLayout>
      <CommonBreadcrumb
        screens={[
          { name: 'Chi tiết chuyến hàng', href: 'shipment' },
          { name: 'Thông tin chuyến hàng', href: '' },
        ]}
      />

      <h6 className="mb-8">Thông tin chuyến hàng {shipmentCode}</h6>

      <GIShipmentTable
        loading={isLoading}
        dataSource={shipmentDetail}
        refetchShipmentDetail={refetchShipmentDetail}
      />
    </MainLayout>
  );
}
