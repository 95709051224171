import { IPagingResponse, IResponse } from 'interface/base.interface';
import axiosClient from './axiosClient';
import {
  IGetMasterCodeQuery,
  ImportRatingQuery,
} from 'interface/permissionCode.interface';

const path = 'abe/permission-code';

const MasterCodeApi = {
  getPermissionCodeList: (
    params?: IGetMasterCodeQuery
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.get(`/${path}`, { params });
  },

  postImportRating: (
    params?: ImportRatingQuery
  ): Promise<IPagingResponse<IResponse>> => {
    return axiosClient.post(`/${path}/import-permission-error`, {
      data: params,
    });
  },
};

export default MasterCodeApi;
