import { Table, Divider } from 'antd';
import Button from 'components/Button';
import GiAPI from 'apis/GiAPI';
import ShipmentApi from 'apis/ShipmentApi';
import { PauseIcon, PlayIcon, StopIcon, ThreeDotIcon } from 'assets/svg';
import useQueryParam from 'hooks/useQueryPrams';
import { IPagingResponse } from 'interface/base.interface';
import { IGiDetail } from 'interface/gi.interface';
import ModalBase from 'components/ModalBase';
import { PR_Monochromatic } from 'assets/images';
import {
  IControlGiAction,
  IShipmentDetail,
} from 'interface/shipment.interface';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import formatDateWithTime from 'utils/formatDateWithTime';
import GIDetailModal from '../GI/GIDetailModal';
import { openNotificationWithIcon } from 'utils/notification';
import handleError401 from 'utils/handleError401';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { HocChangePagination } from 'utils/HocChangePagination';
interface IProps {
  dataSource?: IPagingResponse<IShipmentDetail>;
  refetchShipmentDetail: () => void;
  loading?: boolean;
}

const GiStatus = {
  waiting: 'Chờ xuất kho',
  pending: 'Chờ vận chuyển',
  preparing: 'Đang vận chuyển',
  delivering: 'Đang được giao',
  completed: 'Đã được giao',
  returning: 'Đang trả',
  cancelled: 'Đã hủy',
  done: 'Đã nhập kho',
};

export default function GIShipmentTable(props: IProps): JSX.Element {
  const { dataSource, refetchShipmentDetail, loading } = props;

  const history = useHistory();
  const page = useQueryParam().get('page') || 1;
  const [isVisible, setIsvisible] = useState<boolean>(false);
  const [context, setContext] = useState<{
    action: IControlGiAction;
    contextContent: string;
    giDocument: string;
  }>();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [giDetails, setGiDetails] = useState<IGiDetail>();

  const { mutate: controlGi, isLoading } = useMutation(ShipmentApi.controlGi, {
    onSuccess: () => {
      refetchShipmentDetail();
      setIsvisible(false);
      openNotificationWithIcon('success', 'Cập nhật đơn vận thành công');
    },
    onError: (error: any) => {
      setIsvisible(false);
      openNotificationWithIcon('error', error.response.data.message[0]);
      if (error.response.data.status === 401) {
        handleError401();
      }
    },
  });

  const { mutate: getGiDetail } = useMutation(GiAPI.getGIDT, {
    onSuccess: (data) => {
      setIsModalVisible(true);
      setGiDetails(data);
    },
    onError: (error: any) => {
      setIsModalVisible(false);
      openNotificationWithIcon('error', error.response.data.message[0]);
    },
  });

  const handleIconPress = (icon: string, giDocument: string) => {
    setIsvisible(true);

    switch (icon) {
      case 'continue':
        setContext({
          action: icon,
          contextContent: `Bạn muốn tiếp tục  đơn vận ${giDocument} ?`,
          giDocument,
        });
        break;

      case 'pause':
        setContext({
          action: icon,
          contextContent: `Bạn muốn tạm dừng đơn vận ${giDocument} ?`,
          giDocument,
        });
        break;

      case 'stop':
        setContext({
          action: icon,
          contextContent: `Bạn muốn thu hồi đơn vận ${giDocument} ?`,
          giDocument,
        });
        break;

      default:
        break;
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, record: any) => (
          <span>{(GiStatus as any)?.[record.status]}</span>
        ),
      },
      {
        title: 'Mã GI',
        dataIndex: 'document',
        key: 'document',
      },
      {
        title: 'Ngày tạo',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_: any, record: any) => (
          <span>{formatDateWithTime(record.createdAt)}</span>
        ),
      },
      {
        title: 'Người tạo',
        dataIndex: 'createBy',
        key: 'createBy',
      },
      {
        title: 'Đơn vị xuất',
        dataIndex: 'plant',
        key: 'plant',
      },
      {
        title: 'Đơn vị nhận',
        dataIndex: 'receivingPlant',
        key: 'receivingPlant',
      },
      {
        title: 'Địa chỉ nhận',
        dataIndex: 'receivingPlantAddress',
        key: 'receivingPlantAddress',
      },
      {
        title: 'Địa chỉ giao',
        dataIndex: 'plantAddress',
        key: 'plantAddress',
      },
      {
        title: 'Số điện thoại',
        dataIndex: 'plantPhoneNumber',
        key: 'plantPhoneNumber',
      },
      {
        title: 'Mã GR',
        dataIndex: 'grCode',
        key: 'grCode',
      },
      {
        title: 'Người tạo',
        dataIndex: 'createBy',
        key: 'createBy',
      },
      {
        title: 'Thời gian tạo',
        dataIndex: 'createdQrAt',
        key: 'createdQrAt',
      },
      {
        title: 'Shipment control',
        dataIndex: 'shipmentControl',
        key: 'shipmentControl',
        render: (_: any, record: IShipmentDetail) => (
          <div className="flex justify-center items-center">
            {[
              {
                icon: <PlayIcon />,
                onPress: () => handleIconPress('continue', record.document),
                disable: record.isPause === false || record.isStop === true,
              },
              {
                icon: <PauseIcon />,
                onPress: () => handleIconPress('pause', record.document),
                disable: record.isPause === true || record.isStop === true,
              },
              {
                icon: <StopIcon />,
                onPress: () => handleIconPress('stop', record.document),
                disable: record.isStop === true,
              },
            ].map((x, index) => {
              const disableAllButton = [
                'done',
                'complete',
                'completed',
                'cancelled',
              ].includes(record.status);
              return (
                <button
                  className="outline-none ml-2"
                  key={index}
                  disabled={disableAllButton || x.disable}
                  onClick={x.onPress}
                  style={{
                    opacity: x.disable || disableAllButton ? 0.5 : 1,
                  }}
                >
                  {x.icon}
                </button>
              );
            })}
          </div>
        ),
      },
      {
        title: 'Phương tiện',
        dataIndex: 'vehicleType',
        key: 'vehicleType',
      },
      {
        title: 'Thời gian nhận',
        dataIndex: 'receivedAt',
        key: 'receiviedAt',
      },
      {
        title: 'SDT Shipper',
        dataIndex: 'shipperPhoneNumber',
        key: 'shipperPhoneNumber',
      },
      {
        title: 'Chi tiết',
        dataIndex: 'detail',
        key: 'detail',
        render: (_: any, record: IShipmentDetail) => (
          <div className="flex justify-center">
            <button
              onClick={() => getGiDetail(record.document)}
              className="outline-none"
            >
              <ThreeDotIcon className="flex justify-center" />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Table
        loading={loading}
        className="customTable"
        dataSource={dataSource?.data}
        columns={columns}
        pagination={{
          current: +page,
          pageSize: DEFAULT_PAGE_SIZE,
          total: dataSource?.total,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onChange: HocChangePagination(history),
        }}
      />

      {giDetails && (
        <GIDetailModal
          gi={giDetails}
          isVisible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        />
      )}

      <ModalBase isVisible={isVisible} onCancel={() => setIsvisible(false)}>
        <div className="flex flex-col h-full items-center justify-center">
          <img
            src={PR_Monochromatic}
            alt="PR_Monochromatic"
            className="cursor-pointer"
          />
          <p className="para-bold-2 text-primary-40 my-4">Thông báo!</p>

          <p className="para-2 text-primary-40">{context?.contextContent}</p>

          <Divider />
          <div className="flex justify-between">
            <Button
              size="md"
              onClick={() => setIsvisible(false)}
              color="neutral-60"
              bgColor="transparent"
            >
              Hủy
            </Button>
            <Button
              size="md"
              onClick={() =>
                controlGi({
                  giDocument: context?.giDocument || '',
                  action: context?.action || 'continue',
                })
              }
            >
              Đồng ý
            </Button>
          </div>
        </div>
      </ModalBase>
    </>
  );
}
