/* eslint-disable react-hooks/exhaustive-deps */
import GiAPI from 'apis/GiAPI';
import { MainLayout } from 'components';
import GIEditFilter from 'components/Modules/GI/GIEditFilter';
import GIEditTable from 'components/Modules/GI/GIEditTable';
import useQueryParam from 'hooks/useQueryPrams';
import { IGiParams } from 'interface/gi.interface';
import queryString from 'query-string';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { openNotificationWithIcon } from 'utils/notification';
import { PR_Monochromatic } from 'assets/images';
import ModalBase from 'components/ModalBase';
import { Divider } from 'antd';
import Button from 'components/Button';

export default function EditGIPage(): JSX.Element {
  const [isEditButtonEnable, setIsEditButtonEnable] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [isVisible, setIsvisible] = useState<boolean>(false);

  const history = useHistory();
  const queryParam = useQueryParam();

  const page = parseInt(queryParam.get('page') + '') || 1;
  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;
  const plant = useQueryParam().get('plant') || undefined;
  const createdBy = useQueryParam().get('createdBy') || undefined;
  const receivingPlant = useQueryParam().get('receivingPlant') || undefined;
  const search = useQueryParam().get('search') || undefined;

  const giParams = {
    page,
    page_size,
    plant,
    createdBy,
    receivingPlant,
    search,
  };

  const params: Partial<IGiParams> = JSON.parse(JSON.stringify(giParams));

  const {
    data: gisData,
    isLoading: isGettingGis,
    refetch: refetchGetGi,
  } = useQuery(['getGisToHold', giParams], () => GiAPI.getGisToHold(params));

  const handleSearch = (values: any) => {
    const search = queryString.stringify(values);

    history.push({
      pathname: '/edit-gi',
      search: `${search}&page=1`,
    });
  };

  const { mutate: activeGis, isLoading: isActivatingGis } = useMutation(
    GiAPI.holdGi,
    {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Duyệt xuất khi thành công!');
        refetchGetGi();
        setIsvisible(false);
        setSelectedRows([]);
      },
      onError: (error: any) => {
        const message =
          error?.response?.data?.status === 403
            ? 'Bạn không có quyền để thực hiện chức năng này!.'
            : 'Đã có lỗi sảy ra';
        openNotificationWithIcon('error', message);
      },
    }
  );

  const handleConfirmExport = () => {
    setIsvisible(true);
  };

  const getSelectedRow = (selectedRows: string[]) => {
    setSelectedRows(selectedRows);
  };

  return (
    <MainLayout isLoading={isGettingGis || isActivatingGis}>
      <p className="para-2 text-primary-0 text-opacity-45">Quản lý vận đơn</p>
      <h6 className="text-primary-30 mt-1">Danh sách vận đơn</h6>

      <GIEditFilter
        isShowEditButton={true}
        isEditButtonEnable={isEditButtonEnable}
        handleSearch={handleSearch}
        handleConfirmExport={handleConfirmExport}
      />

      <GIEditTable
        hasSelection={true}
        dataSource={gisData}
        page={page}
        page_size={page_size}
        // total={gisData?.total}
        disableButton={() => setIsEditButtonEnable(false)}
        enableButton={() => setIsEditButtonEnable(true)}
        getSelectedRow={getSelectedRow}
      />

      <ModalBase isVisible={isVisible} onCancel={() => setIsvisible(false)}>
        <div className="flex flex-col h-full items-center justify-center">
          <img
            src={PR_Monochromatic}
            alt="PR_Monochromatic"
            className="cursor-pointer"
          />
          <p className="para-bold-2 text-primary-40 my-4">Thông báo!</p>

          <p className="para-2 text-primary-40">
            Bạn muốn điều chỉnh các phiếu xuất đã chọn
          </p>

          <Divider />

          <div className="flex justify-between">
            <Button
              size="md"
              onClick={() => setIsvisible(false)}
              color="neutral-60"
              bgColor="transparent"
            >
              Hủy
            </Button>

            <Button size="md" onClick={() => activeGis(selectedRows)}>
              Đồng ý
            </Button>
          </div>
        </div>
      </ModalBase>
    </MainLayout>
  );
}
