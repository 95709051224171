import React, { useState } from 'react';
import { Input, Row, Tag } from 'antd';
import Button from 'components/Button';
import RatingApi from 'apis/RatingApi';
import { useMutation } from 'react-query';
import { openNotificationWithIcon } from 'utils/notification';

interface IProps {
  rating?: any;
  isComment?: boolean;
}
const CommentInput = (props: IProps) => {
  const { rating, isComment } = props;
  const [comment, setComment] = useState(
    isComment ? rating?.comment_pnjp : rating?.note_pnjp
  );

  const { mutate: putUpdateInfoRating } = useMutation(
    RatingApi.putUpdateInfoRating,
    {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Cập nhật thành công');
      },
      onError: (error) => {
        console.log(error);
        openNotificationWithIcon('error', 'Cập nhật thất bại');
      },
    }
  );

  return (
    <Row style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
      <Input
        style={{ width: '140px' }}
        value={comment}
        defaultValue={isComment ? rating?.comment_pnjp : rating?.note_pnjp}
        onChange={(e) => setComment(e.target.value)}
      />
      <Tag
        onClick={() =>
          putUpdateInfoRating({
            id: rating?.id,
            error_code: rating?.error_code,
            prod_origin_code: rating?.prod_origin_code,
            material: rating?.material,
            product_name: rating?.product_name,
            url_image: rating?.url_image,
            quantity: rating?.quantity?.toString(),
            ...(isComment &&
              comment &&
              comment.length > 0 && { comment_pnjp: comment }),
            ...(!isComment &&
              comment &&
              comment.length > 0 && { note_pnjp: comment }),
          })
        }
        className="outline-none"
        color="blue"
        style={{ height: '31px', marginLeft: '6px', paddingTop: '4px' }}
      >
        Lưu
      </Tag>
    </Row>
  );
};

export default CommentInput;
