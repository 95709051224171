import { ReactNode } from 'react';

export interface IProps {
  children: ReactNode;
  bgColor?: string;
  borderRadius?: '2.5xl' | 'md';
  size?: 'sm' | 'md';
  type?: 'submit' | 'button';
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
}

export default function Button(props: IProps): JSX.Element {
  const {
    children,
    bgColor = 'primary-50',
    borderRadius = '2.5xl',
    size = 'sm',
    type = 'button',
    onClick,
    disabled = false,
    color = 'white',
  } = props;

  const padding = size === 'sm' ? 'py-2 px-6' : 'py-4 px-20';
  const opacity = disabled ? '20' : '100';

  return (
    <button
      type={type}
      className={`bg-${bgColor} opacity-${opacity} rounded-${borderRadius} text-${color} para-bold-1 ${padding} drop-shadow-button border-2`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
