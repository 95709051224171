/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Input, Table, Button } from 'antd';
import PermissionApi from 'apis/PermissionApi';
import { CheckIcon } from 'assets/svg';
import CreateJobTitleModal from 'components/Modules/JobTitle/CreateJobTitleModal';
import UpdateJobTitleModal from 'components/Modules/JobTitle/UpdateJobTitleModal';
// import Button from 'components/Button';
import MainLayout from 'components/Modules/Layout/MainLayout';
import useQueryParam from 'hooks/useQueryPrams';
import {
  ICreateJobTitle,
  IJobTitle,
  IJobTitleQuery,
} from 'interface/permission.interface';
import { IGetUserQuery, IUser } from 'interface/user.interface';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import formatDateWithTime from 'utils/formatDateWithTime';
import handleError401 from 'utils/handleError401';
import { HocChangePagination } from 'utils/HocChangePagination';
import { openNotificationWithIcon } from 'utils/notification';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';

export default function JobTitlePage(): JSX.Element {
  const [isCreateJobTitleVisible, setIsCreateJobTitleVisible] =
    useState<boolean>(false);

  const [isUpdateJobTitleVisible, setIsUpdateJobTitleVisible] =
    useState<boolean>(false);

  const [currentJobTitle, setCurrentJobTitle] = useState<IJobTitle>();

  const history = useHistory();

  const queryParam = useQueryParam();

  const page = parseInt(queryParam.get('page') + '') || 1;

  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const search = queryParam.get('search') || undefined;
  const active = queryParam.get('active') || undefined;

  const jobTitleParams: IJobTitleQuery = {
    page,
    page_size,
    search,
    active: active === 'true' ? true : active === 'false' ? false : undefined,
  };

  const params = JSON.parse(JSON.stringify(jobTitleParams));

  const {
    data: jobTitles,
    refetch: refetchJobTitle,
    isLoading: loadingGetJobTitle,
  } = useQuery(
    ['getJobTitle', jobTitleParams],
    () => PermissionApi.queryJobTitle(params),
    {
      onError: (error: any) => {
        if (error.response.data.status === 401) {
          handleError401();
        }
      },
    }
  );

  const { mutate: createJobTitle, isLoading: loadingCreateJobTitle } =
    useMutation(PermissionApi.mutationJobTitle, {
      onSuccess: () => {
        refetchJobTitle();
        openNotificationWithIcon('success', 'Thêm jobTitle thành công');
        setIsCreateJobTitleVisible(false);
      },
      onError: () => {
        openNotificationWithIcon('error', 'Thêm jobTitle  thất bại');
      },
    });

  const { mutate: updateJobTitle, isLoading: loadingUpdateJobTitle } =
    useMutation(PermissionApi.mutationJobTitle, {
      onSuccess: () => {
        refetchJobTitle();
        openNotificationWithIcon('success', 'Sửa jobTitle thành công');
        setIsUpdateJobTitleVisible(false);
      },
      onError: () => {
        openNotificationWithIcon('error', 'Sửa jobTitle  thất bại');
      },
    });

  const columns = [
    {
      title: 'Mã Code',
      dataIndex: 'jobTitleCode',
    },
    {
      title: 'Chức danh',
      dataIndex: 'jobTitle',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
    },
    {
      title: 'Hoạt động',
      dataIndex: 'active',
      render: (active: IJobTitle) => {
        console.log({ active });
        return (
          <div className="flex justify-center">{active && <CheckIcon />}</div>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => {
        return formatDateWithTime(createdAt);
      },
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      render: (updatedAt: Date) => {
        return formatDateWithTime(updatedAt);
      },
    },
  ];

  const onFinish = (values: any) => {
    const search: Partial<IGetUserQuery> = JSON.parse(
      JSON.stringify({
        ...values,
        page: 1,
        page_size: page_size,
      })
    );

    history.push({
      pathname: location.pathname,
      search: updateQueryStringParameter(location.search, search),
    });
  };

  const onHandleCreate = (values: ICreateJobTitle) => {
    createJobTitle(values);
  };

  const onHandleUpdate = (values: ICreateJobTitle) => {
    updateJobTitle(values);
  };

  const handleOnRowClick = (record: IJobTitle) => {
    setCurrentJobTitle(record);
    setIsUpdateJobTitleVisible(true);
  };

  return (
    <MainLayout
      isLoading={
        loadingGetJobTitle || loadingCreateJobTitle || loadingUpdateJobTitle
      }
    >
      <div className="flex justify-between">
        <Form
          layout="inline"
          className="items-center"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="search">
            <Input placeholder="Tìm kiếm" className="w-44" />
          </Form.Item>

          {/* <Form.Item name="active">
            <Input placeholder="Tìm kiếm" className="w-44" />
          </Form.Item> */}

          <Form.Item>
            <Button htmlType="submit" type="primary">
              Tìm kiếm
            </Button>
          </Form.Item>
        </Form>

        <Button type="primary" onClick={() => setIsCreateJobTitleVisible(true)}>
          Tạo Mới
        </Button>
      </div>

      <div className="main-content">
        <Table
          rowKey={(record) => record.jobTitleCode}
          columns={columns}
          className="customTable"
          dataSource={jobTitles?.data}
          size="small"
          onRow={(record: IJobTitle) => {
            return {
              onClick: () => handleOnRowClick(record),
            };
          }}
          // loading={getUsersLoading}
          pagination={{
            current: page,
            pageSize: page_size,
            total: jobTitles?.total,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50'],
            onChange: HocChangePagination(history),
          }}
        />
      </div>
      <CreateJobTitleModal
        isModalVisible={isCreateJobTitleVisible}
        onCancel={() => setIsCreateJobTitleVisible(false)}
        mutationUser={onHandleCreate}
      />

      <UpdateJobTitleModal
        isModalVisible={isUpdateJobTitleVisible}
        onCancel={() => setIsUpdateJobTitleVisible(false)}
        mutationUser={onHandleUpdate}
        currentJobTitle={currentJobTitle}
      ></UpdateJobTitleModal>
    </MainLayout>
  );
}
