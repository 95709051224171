/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Carousel, Image, Spin } from 'antd';
import AuthApi from 'apis/AuthApi';
import { background, logo_pnj, materials } from 'assets/images';
import { useUser } from 'contexts/User';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { openNotificationWithIcon } from 'utils/notification';
import { getLogoutUri } from '../utils/getLogouURI';

const onboards = [
  {
    title: 'Ứng dụng PNJ TSM',
    sologan:
      'Ứng dụng giao nhận hàng hóa,\n Giao đúng người - Nhận đúng thời điểm',
  },
];

const SignInPage = (): JSX.Element => {
  const { getUser } = useUser();

  const history = useHistory();

  const query: any = queryString.parse(window.location.search);

  const code = query.code;

  const { mutate: signin, isLoading } = useMutation(AuthApi.signIn, {
    onSuccess: (data) => {
      localStorage.setItem('token', data.token);
      history.push('/gi');
      getUser();
    },
    onError: (error: any) => {
      openNotificationWithIcon('error', error.response.data.message[0]);
      history.push('/');
      localStorage.removeItem('token');
      const logout_uri = getLogoutUri();
      window.location.replace(logout_uri);
    },
  });

  useEffect(() => {
    if (!code) return;
    signin(code);
  }, [code]);

  const onSubmit = async () => {
    window.location.replace(process.env.REACT_APP_AWS_COGNITO_LOGIN as string);
  };

  return (
    <Spin tip="Loading..." size="large" spinning={isLoading}>
      <div
        className="flex h-screen"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="flex flex-col items-center justify-center w-full h-full bg-white">
          <Image src={logo_pnj} alt="logo" preview={false} />
          <button
            className="py-3 mt-12 font-bold text-white bg-primary-50 px-7 rounded-3xl"
            onClick={onSubmit}
          >
            Đăng nhập
          </button>
          <p className="mt-3 ">
            <span className="mr-1 para-2 text-primary-30">
              Bạn cần hỗ trợ thông tin?
            </span>
            <span className="text-secondary-40 para-bold-2">Nhấn vào đây</span>
          </p>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Image
            src={materials}
            alt="materials"
            preview={false}
            className="mb-5"
          />
          <Carousel autoplay={true}>
            {onboards.map((onboard, index) => (
              <div key={index}>
                <p className="p-4 text-center text-white para-bold-1">
                  {onboard.title}
                </p>
                <p className="text-center text-white whitespace-pre  opacity-70 text-wrap">
                  {onboard.sologan}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </Spin>
  );
};

export default SignInPage;
