import { Button, Form, Input, Modal } from 'antd';
import { Select } from 'antd';

const { Option } = Select;
import { OauthClient, Scope } from 'interface/oauth_client';

interface Props {
  client?: OauthClient;
  submit: (values: any) => void;
  isModalVisible: boolean;
  onCancel: () => void;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function UpdateClientModal(props: Props): JSX.Element {
  const [form] = Form.useForm();

  const { submit, client, isModalVisible, onCancel } = props;

  const onSubmit = submit;

  return (
    <Modal
      title="Cập nhật client"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        name="basic"
        // initialValues={client}
        onFinish={onSubmit}
        form={form}
        className="m-auto w-4/5"
        {...formLayout}
      >
        <Form.Item
          label="Name"
          name="name"
          initialValue={client?.name}
          rules={[{ required: true, message: "Please input client's name!" }]}
        >
          <Input placeholder="Enter client's name" />
        </Form.Item>
        <Form.Item
          label="Grants"
          name="grants"
          initialValue={client?.grants[0]}
          rules={[{ required: true, message: 'Please input grants!' }]}
        >
          <Select defaultValue={'Select a grant'}>
            <Option value="client_credentials">client_credentials</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Scope"
          name="scope"
          initialValue={client?.scope}
          rules={[{ required: true, message: 'Please select an scope!' }]}
        >
          <Select defaultValue={'Select a scope'}>
            <Option value={Scope.Read}>Read</Option>
            <Option value={Scope.Write}>Write</Option>
            <Option value={Scope.ReadWrite}>Read and Write</Option>
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
          <Button type="primary" htmlType="submit" className="w-full">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateClientModal;
