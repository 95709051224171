export const titles: {
  path: string;
  param: string;
  setTitle: (key?: string | number) => string;
}[] = [
  {
    path: '/',
    param: '',
    setTitle: () => 'Trang Chủ',
  },
  {
    path: '/sign-in',
    param: '',
    setTitle: () => 'Đăng nhập',
  },
  {
    path: '/gi',
    param: '',
    setTitle: () => 'Danh sách đơn vận',
  },
  {
    path: '/edit-gi',
    param: '',
    setTitle: () => 'Điều chỉnh phiếu xuất',
  },
  {
    path: '/transport-management',
    param: '',
    setTitle: () => 'Quản lý phương tiện giao thông',
  },
  {
    path: '/geo-map',
    param: '',
    setTitle: () => 'Geo Map',
  },
  {
    path: '/clients-management',
    param: '',
    setTitle: () => 'Quản lý clients',
  },
  {
    path: '/clients-management/new-client',
    param: '',
    setTitle: () => 'Tạo mới client',
  },
  {
    path: '/clients-management/edit-client',
    param: '',
    setTitle: () => 'Chỉnh sửa client',
  },
  {
    path: '/plant',
    param: '',
    setTitle: () => 'Quản lý plant',
  },
  {
    path: '/plant/:id',
    param: 'id',
    setTitle: (key) => `Cập nhật plant ${key}`,
  },
  {
    path: '/role-based-auth',
    param: '',
    setTitle: () => 'Phân quyền nhân viên',
  },
  {
    path: '/groups',
    param: '',
    setTitle: () => 'Danh sách nhóm',
  },
  {
    path: '/functions',
    param: '',
    setTitle: () => 'Danh sách quyền',
  },
  {
    path: '/bill-of-lading',
    param: '',
    setTitle: () => '',
  },
  {
    path: '/bill-of-lading:id',
    param: '',
    setTitle: () => '',
  },
  {
    path: '/shipment',
    param: '',
    setTitle: () => 'Quản lý chuyến hàng',
  },
  {
    path: '/shipment/:shipmentCode',
    param: 'shipmentCode',
    setTitle: (key) => `Thông tin chuyến hàng ${key}`,
  },
  {
    path: '/route',
    param: '',
    setTitle: () => 'Quản lý tuyến đường',
  },
  {
    path: '/job-title',
    param: '',
    setTitle: () => 'Quản lý mã chức vụ',
  },
  {
    path: '/mapping-job-title',
    param: '',
    setTitle: () => 'Quản lý tự động gán nhóm',
  },
  {
    path: '/rating',
    param: '',
    setTitle: () => 'Rating - Đánh giá',
  },
  {
    path: '/master-code',
    param: '',
    setTitle: () => 'Danh sách mã lỗi',
  },
  {
    path: '/permission-code',
    param: '',
    setTitle: () => 'Quản lý quyền mã lỗi',
  },
  {
    path: '*',
    param: '',
    setTitle: () => 'Trang không tồn tại',
  },
];
