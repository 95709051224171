import { DownOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Select } from 'antd';
import Button from 'components/Button';
import useQueryParam from 'hooks/useQueryPrams';
import moment from 'moment';
import { Moment } from 'moment';
import { useState } from 'react';
import { ratingGiStatusPHHH, ratingStatus } from 'utils/constants';
import { renderObjectOptions } from 'utils/renderOptions';

interface IProps {
  // isShowEditButton?: boolean;
  // isEditButtonEnable?: boolean;
  // values?: any;
  handleSearch: (values: any) => void;
  // handleConfirmExport?: () => void;
}

export default function RatingFilter(props: IProps): JSX.Element {
  const {
    // isShowEditButton = false,
    // isEditButtonEnable,
    handleSearch,
    // handleConfirmExport,
  } = props;

  const MAX_RANGE = 30;

  const { RangePicker } = DatePicker;

  const onFinish = (values: any) => {
    const params = {
      ...values,
      fromDate: value?.[0]?.toDate().toLocaleDateString('fr-CA'),
      toDate: value?.[1]?.toDate().toLocaleDateString('fr-CA'),
    };
    // Object.keys(params).forEach((k) => params[k] == '' && delete params[k]);
    Object.keys(params).forEach((k) => {
      if (params[k] == '') params[k] = undefined;
    });

    handleSearch(params);
  };
  const queryParam = useQueryParam();

  const [dates, setDates] = useState<Moment[]>([]);
  const [hackValue, setHackValue] = useState<any>();
  const [value, setValue] = useState<Moment[]>([moment(), moment()]);

  const disabledDate = (current: Moment) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > MAX_RANGE;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > MAX_RANGE;
    return tooEarly || tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  return (
    <div className="flex justify-between items-center mt-8 mb-10">
      <Form
        layout="inline"
        className="items-center"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="receiving_plant_name">
          <Input placeholder="Tên cửa hàng" />
        </Form.Item>

        <Form.Item name="gi_id">
          <Input placeholder="Mã GI" />
        </Form.Item>

        <Form.Item name="gr_id">
          <Input placeholder="Mã GR" />
        </Form.Item>

        <Form.Item name="status">
          <Select placeholder="Trạng thái">
            {renderObjectOptions(ratingStatus)}
          </Select>
        </Form.Item>

        <Form.Item name="gi_status_phhh">
          <Select placeholder="Trạng thái gửi phản hồi">
            {renderObjectOptions(ratingGiStatusPHHH)}
          </Select>
        </Form.Item>

        <RangePicker
          value={hackValue || value}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setDates(val as Moment[])}
          onChange={(val) => setValue(val as Moment[])}
          onOpenChange={onOpenChange}
          placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
        />

        <Form.Item>
          <Button type="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
