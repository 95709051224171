import { Table } from 'antd';
import GiAPI from 'apis/GiAPI';
import { CheckIcon, ThreeDotIcon } from 'assets/svg';
import { IGi } from 'interface/gi.interface';
import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { calculateColumnsWidth } from 'utils/calculateWidthColumn';
import formatDateWithTime from 'utils/formatDateWithTime';
import { HocChangePagination } from 'utils/HocChangePagination';
import { translateGIStatus } from 'utils/translateStatus';
import GIDetailModal from './GIDetailModal';
interface IProps {
  dataSource: any[];
  disableButton?: () => void;
  enableButton?: () => void;
  hasSelection: boolean;
  page: number;
  pageSize: number;
  total: number;
  getSelectedRow?: (documents: string[]) => void;
}

export default function GITable(props: IProps): JSX.Element {
  const {
    dataSource,
    disableButton,
    enableButton,
    hasSelection,
    page,
    total,
    getSelectedRow,
    pageSize,
  } = props;

  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentGi, setCurrentGi] = useState<string>('');

  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    if (!enableButton || !disableButton) return;

    if (selectedRowKeys.length > 0) {
      enableButton();
    } else {
      disableButton();
    }

    const documents = selectedRows.map((row: any) => row.document);

    getSelectedRow && getSelectedRow(documents);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  const handleShowGIDetailModal = (record: IGi) => {
    setIsModalVisible(true);
    setCurrentGi(record.document);
  };

  const { data: GIDetials } = useQuery(
    ['getGIDetails', currentGi],
    () => GiAPI.getGIDT(currentGi),
    { enabled: !!currentGi }
  );

  const columns: any = [
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <span>{translateGIStatus(record.status)}</span>
      ),
      fixed: 'left',
    },
    {
      title: 'Mã GI',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: any, record: IGi) => (
        <span>{formatDateWithTime(record.createdAt)}</span>
      ),
    },
    {
      title: 'Người tạo',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: 'Đã duyệt',
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean) => (
        <div className="flex justify-center">{active && <CheckIcon />}</div>
      ),
    },
    {
      title: 'Đơn vị xuất',
      dataIndex: 'plant',
      key: 'plant',
    },
    {
      title: 'Đơn vị nhận',
      dataIndex: 'receivingPlant',
      key: 'receivingPlant',
    },
    {
      title: 'Địa chỉ nhận',
      dataIndex: 'receivingPlantAddress',
      key: 'receivingPlantAddress',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'plantPhoneNumber',
      key: 'plantPhoneNumber',
    },
    {
      title: 'Mã GR',
      dataIndex: 'gr',
      key: 'gr',
    },
    {
      title: 'Người tạo',
      dataIndex: 'grCreatedBy',
      key: 'grCreatedBy',
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'grCreatedAt',
      key: 'grCreatedAt',
      render: (_: any, record: IGi) => (
        <span>
          {record?.grCreatedAt
            ? moment(record.grCreatedAt).format('DD/MM/YYYY')
            : ''}
        </span>
      ),
    },
    {
      title: 'Shipper',
      dataIndex: 'shipperName',
      key: 'shipperName',
    },
    {
      title: 'Phương tiện',
      dataIndex: 'vehicleType',
      key: 'vehicleType',
    },
    {
      title: 'Thời gian nhận',
      dataIndex: 'shipperDeliveryAt',
      key: 'shipperDeliveryAt',
      render: (_: any, record: IGi) => (
        <span>
          {record?.shipperDeliveryAt
            ? moment(record.shipperDeliveryAt).format('DD/MM/YYYY')
            : ''}
        </span>
      ),
    },
    {
      title: 'SDT Shipper',
      dataIndex: 'shipperPhoneNumber',
      key: 'shipperPhoneNumber',
    },
    {
      title: 'Chi tiết',
      dataIndex: 'detail',
      key: 'detail',
      fixed: 'right',
      render: (_: any, record: IGi) => (
        <div className="flex justify-center">
          <button
            onClick={() => handleShowGIDetailModal(record)}
            className="outline-none"
          >
            <ThreeDotIcon className="flex justify-center" />
          </button>
        </div>
      ),
    },
  ];

  const maxWidthPerCell = 600;
  const tableHeight = 500;
  const rowKey = 'id';

  // This helper function helps to calculate the width for each column
  // based on all table cells - column cell and source cell
  const dataTable = calculateColumnsWidth(
    columns,
    dataSource as [],
    maxWidthPerCell
  );

  return (
    <>
      <Table
        rowKey={rowKey}
        scroll={{ x: dataTable.tableWidth, y: tableHeight }}
        className="customTable"
        rowSelection={hasSelection ? rowSelection : undefined}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          // defaultCurrent: page,
          current: page,
          // defaultPageSize: 20,
          pageSize: pageSize,
          total: total,
          onChange: HocChangePagination(history),
        }}
      />
      {GIDetials && (
        <GIDetailModal
          gi={GIDetials}
          isVisible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
}
