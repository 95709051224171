import { Breadcrumb } from 'antd';
import { Link, useHistory } from 'react-router-dom';

interface IProps {
  screens: { name: string; href: string }[];
}

const CommonBreadcrumb = (props: IProps): JSX.Element => {
  const { screens } = props;

  const history = useHistory();

  const handleItemClicked = () => {
    history.goBack();
  };

  return (
    <Breadcrumb>
      {screens.map((screen, index) => (
        <Breadcrumb.Item
          key={screen.name}
          onClick={() => screens[screens.length - 2] && handleItemClicked()}
          className="cursor-pointer"
        >
          {screen.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default CommonBreadcrumb;
