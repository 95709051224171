/* eslint-disable @typescript-eslint/no-explicit-any */
import MainLayout from 'components/Modules/Layout/MainLayout';
import { useHistory } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report, Embed, service } from 'powerbi-client';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import PowerBiApi from 'apis/PowerBIApi';
import { IReport } from 'interface/powerbi.interface';

function Quality(): JSX.Element {
  // PowerBI Report object (to be received via callback)
  const [report, setReport] = useState<Report>();

  const myReport: IReport = {
    groupId: '175e6400-43d9-48bd-a582-fb4bca86eff2',
    reportId: '6d844371-6d02-4923-9678-9457d546f3f7',
  };

  // API end-point url to get access token for report
  const { mutate: getToken, data: accessToken } = useMutation(
    PowerBiApi.getAccessBearer
  );

  useEffect(() => {
    getToken();
  }, []);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      function () {
        console.log('Report has rendered');

        // Update display message
        setMessage('The report is rendered');
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  const [displayMessage, setMessage] = useState(
    `The report is bootstrapped. Click the Embed Report button to set the access token`
  );

  const history = useHistory();

  return (
    <MainLayout>
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${myReport.reportId}&groupId=${myReport.groupId}&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUVBU1QtQVNJQS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19`,
          id: myReport.reportId,
          tokenType: models.TokenType.Aad,
          accessToken: accessToken?.access_token,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        eventHandlers={eventHandlersMap}
        cssClassName={'report-style-class'}
        getEmbeddedComponent={(embedObject: Embed) => {
          console.log(
            `Embedded object of type "${embedObject.embedtype}" received`
          );
          setReport(embedObject as Report);
        }}
      />
    </MainLayout>
  );
}

export default Quality;
