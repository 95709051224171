/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Input, Table, Button } from 'antd';
import PermissionApi from 'apis/PermissionApi';
import { CheckIcon } from 'assets/svg';
import MainLayout from 'components/Modules/Layout/MainLayout';
import useQueryParam from 'hooks/useQueryPrams';
import { IGetUserQuery } from 'interface/user.interface';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import formatDateWithTime from 'utils/formatDateWithTime';
import handleError401 from 'utils/handleError401';
import { HocChangePagination } from 'utils/HocChangePagination';
import { openNotificationWithIcon } from 'utils/notification';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';
import {
  ICreateMappingJobTitle,
  IGroup,
  IJobTitle,
  IJobTitleQuery,
  IMappingJobTitle,
} from 'interface/permission.interface';
import CreateMappingJobTitleModal from 'components/Modules/JobTitle/CreateMappingJobTitleModal';
import UpdateMappingJobTitleModal from 'components/Modules/JobTitle/UpdateMappingJobTitleModal';

export default function MappingJobTitlePage(): JSX.Element {
  const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
  const [isUpdateVisible, setIsUpdateVisible] = useState<boolean>(false);
  const [currentMappingJobTitle, setCurrentMappingJobTitle] =
    useState<IMappingJobTitle>();

  const history = useHistory();

  const queryParam = useQueryParam();

  const page = parseInt(queryParam.get('page') + '') || 1;

  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const search = queryParam.get('search') || undefined;
  const active = queryParam.get('active') || undefined;

  const mappingJobTitleParams: IJobTitleQuery = {
    page,
    page_size,
    search,
    active: active === 'true' ? true : active === 'false' ? false : undefined,
  };

  const params = JSON.parse(JSON.stringify(mappingJobTitleParams));

  const {
    data: mappingJobTitle,
    refetch: refetchMappingJobTitle,
    isLoading: loading,
  } = useQuery(
    ['getMappingJobTitle', mappingJobTitleParams],
    () => PermissionApi.queryMappingJobTitle(params),
    {
      onError: (error: any) => {
        if (error.response.data.status === 401) {
          handleError401();
        }
      },
    }
  );

  const { mutate: createMappingJobTitle, isLoading: loadingCreate } =
    useMutation(PermissionApi.mutaionMappingJobTitle, {
      onSuccess: () => {
        refetchMappingJobTitle();
        getGroups({ page: 1, page_size: 99999 });
        openNotificationWithIcon('success', 'Thêm MappingJobTitle thành công');
        setIsCreateVisible(false);
      },
      onError: () => {
        openNotificationWithIcon('error', 'Thêm MappingJobTitle  thất bại');
      },
    });

  const { mutate: updateMappingJobTitle, isLoading: loadingUpdate } =
    useMutation(PermissionApi.mutaionMappingJobTitle, {
      onSuccess: () => {
        refetchMappingJobTitle();
        openNotificationWithIcon('success', 'Sửa MappingJobTitle thành công');
        setIsUpdateVisible(false);
      },
      onError: () => {
        openNotificationWithIcon('error', 'Sửa MappingJobTitle  thất bại');
      },
    });

  const { mutate: getGroups, data: groups } = useMutation(
    PermissionApi.getGroups
  );

  const { mutate: getMappedJobTitles, data: unAssignJobTitles } = useMutation(
    PermissionApi.queryJobTitle
  );

  useEffect(() => {
    getGroups({ page: 1, page_size: 99999 });
    getMappedJobTitles({ page: 1, page_size: 99999, unassigned: true });
  }, []);

  const columns = [
    {
      title: 'Mã Chức Vụ',
      dataIndex: 'jobTitleCode',
    },
    {
      title: 'Tên Chức Vụ',
      dataIndex: 'jobTitle',
      render: (record: IJobTitle) => {
        return record?.jobTitle;
      },
    },
    {
      title: 'Mã Nhóm',
      dataIndex: 'groupName',
    },
    {
      title: 'Tên Nhóm',
      dataIndex: 'group',
      render: (record: IGroup) => {
        return record?.title;
      },
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
    },
    {
      title: 'Hoạt động',
      dataIndex: 'active',
      render: (active: IMappingJobTitle) => {
        return (
          <div className="flex justify-center">{active && <CheckIcon />}</div>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => {
        return formatDateWithTime(createdAt);
      },
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      render: (updatedAt: Date) => {
        return formatDateWithTime(updatedAt);
      },
    },
  ];

  const onFinish = (values: any) => {
    const search: Partial<IGetUserQuery> = JSON.parse(
      JSON.stringify({
        ...values,
        page: 1,
        page_size: page_size,
      })
    );

    history.push({
      pathname: location.pathname,
      search: updateQueryStringParameter(location.search, search),
    });
  };

  const onHandleCreate = (values: ICreateMappingJobTitle) => {
    createMappingJobTitle(values);
  };

  const onHandleUpdate = (values: ICreateMappingJobTitle) => {
    updateMappingJobTitle(values);
  };

  const handleOnRowClick = (record: IMappingJobTitle) => {
    setCurrentMappingJobTitle(record);
    setIsUpdateVisible(true);
  };

  return (
    <MainLayout isLoading={loading || loadingCreate || loadingUpdate}>
      <div className="flex justify-between">
        <Form
          layout="inline"
          className="items-center"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="search">
            <Input placeholder="Tìm kiếm" className="w-44" />
          </Form.Item>

          {/* <Form.Item name="active">
            <Input placeholder="Tìm kiếm" className="w-44" />
          </Form.Item> */}

          <Form.Item>
            <Button htmlType="submit" type="primary">
              Tìm kiếm
            </Button>
          </Form.Item>
        </Form>

        <Button type="primary" onClick={() => setIsCreateVisible(true)}>
          Tạo Mới
        </Button>
      </div>

      <div className="main-content">
        <Table
          rowKey={(record) => record.jobTitleCode}
          columns={columns}
          className="customTable"
          dataSource={mappingJobTitle?.data}
          size="small"
          onRow={(record: IMappingJobTitle) => {
            return {
              onClick: () => handleOnRowClick(record),
            };
          }}
          pagination={{
            current: page,
            pageSize: page_size,
            total: mappingJobTitle?.total,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50'],
            onChange: HocChangePagination(history),
          }}
        />
      </div>
      {/* {/* <CreateJobTitleModal
        isModalVisible={isCreateJobTitleVisible}
        onCancel={() => setIsCreateJobTitleVisible(false)}
        mutationUser={onHandleCreate}
      /> */}

      <CreateMappingJobTitleModal
        isModalVisible={isCreateVisible}
        onCancel={() => setIsCreateVisible(false)}
        mutationUser={onHandleCreate}
        groups={groups?.data}
        jobTitles={unAssignJobTitles?.data}
      />

      <UpdateMappingJobTitleModal
        isModalVisible={isUpdateVisible}
        onCancel={() => setIsUpdateVisible(false)}
        mutationUser={onHandleUpdate}
        groups={groups?.data}
        currentMappingJobTitle={currentMappingJobTitle}
      />
    </MainLayout>
  );
}
