/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table, Form, Input } from 'antd';
import PlantAPI from 'apis/PlantAPI';
import { MagifyingGlassIcon } from 'assets/svg';
import { MainLayout } from 'components';
import useQueryParam from 'hooks/useQueryPrams';
import { IPlant } from 'interface/plant.interface';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';
import Button from 'components/Button';
import moment from 'moment';
import { HocChangePagination } from 'utils/HocChangePagination';

export default function PlantManagementPage(): JSX.Element {
  const history = useHistory();
  const queryParam = useQueryParam();

  const page = Number(queryParam.get('page') + '') || 1;
  const page_size =
    Number(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;
  const search = queryParam.get('search') || undefined;
  const plantCode = queryParam.get('plantCode') || undefined;
  const plantName = queryParam.get('plantName') || undefined;
  const city = queryParam.get('city') || undefined;
  const district = queryParam.get('district') || undefined;

  const plantParams = {
    page,
    page_size,
    search,
    plantCode,
    plantName,
    city,
    district,
  };

  const param = JSON.parse(JSON.stringify(plantParams));

  //fetch plant data
  const {
    data: plantData,
    isLoading: isLoadingPlant,
    isError: isPlantError,
    error: plantError,
  } = useQuery(['getPlants', plantParams], () => PlantAPI.getPlants(param));

  const handlePlantClick = (plant: IPlant) => {
    history.push(`/plant/${plant.code}`);
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Tỉnh/Thành phố',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Quận/Huyện',
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Tọa độ',
      dataIndex: 'location',
      key: 'location',
      render: (_: any, record: any) => {
        const { latitude = 0, longitude = 0 } = record;
        const lat = Math.round(latitude * 100) / 100;
        const lon = Math.round(longitude * 100) / 100;
        return lat && lon ? <span>{`${lat},${lon}`}</span> : '';
      },
    },
    {
      title: 'Cập nhật lúc',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_: any, record: any) => (
        <span>{moment(record.updatedAt)?.format('DD/MM/YYYY HH:mm')}</span>
      ),
    },
  ];

  const onFinish = (data: any) => {
    const shipmentParams = JSON.parse(
      JSON.stringify({
        ...data,
        page: 1,
      })
    );
    history.push({
      pathname: location.pathname,
      search: updateQueryStringParameter(location.search, shipmentParams),
    });
  };

  return (
    <>
      <MainLayout
        isLoading={isLoadingPlant}
        isError={isPlantError}
        error={(plantError as any)?.response.data.message[0]}
      >
        <Form layout="inline" className="items-center mb-8" onFinish={onFinish}>
          <Form.Item name="search">
            <Input
              defaultValue={search}
              placeholder="Tìm kiếm"
              suffix={<MagifyingGlassIcon />}
            />
          </Form.Item>

          <Form.Item name="plantCode">
            <Input defaultValue={plantCode} placeholder="Mã Chi Nhánh" />
          </Form.Item>

          <Form.Item name="plantName">
            <Input defaultValue={plantName} placeholder="Tên Chi Nhánh" />
          </Form.Item>

          <Form.Item name="city">
            <Input defaultValue={city} placeholder="Tỉnh/Thành Phố" />
          </Form.Item>

          <Form.Item name="district">
            <Input defaultValue={district} placeholder="Quận/Huyện" />
          </Form.Item>

          <Form.Item>
            <Button type="submit">Tìm kiếm</Button>
          </Form.Item>
        </Form>

        <Table
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: (event) => {
                event.preventDefault();
                handlePlantClick(record);
              },
            };
          }}
          columns={columns}
          dataSource={plantData?.data}
          pagination={{
            defaultCurrent: +page,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            total: plantData?.total,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50'],
            onChange: HocChangePagination(history),
          }}
        />
        <>
          <b style={{ color: 'red', marginRight: '1rem' }}>Lưu ý:</b>
          <span>
            Vì latitude, longitude quá dài nên rút gọn lại 2 chữ số sau dấu .
          </span>
        </>
      </MainLayout>
    </>
  );
}
