export enum Scope {
  Read = 'read',
  Write = 'write',
  ReadWrite = 'read,write',
}

export interface OauthClient {
  id: string;
  secret: string;
  name: string;
  grants: string;
  scope: Scope;
  createdAt: string;
  createBy: string;
}

export interface IOauthClientDetail {
  grants: string[];
  id: string;
  name: string;
  scope: Scope;
  secret: string;
  user: {
    id: string;
    name: string;
  };
}

export interface CreateOauthClient {
  name: string;
  grants: string;
  scope: Scope;
}

export interface EditOauthClient extends CreateOauthClient {
  id: string;
}

// "id": "64a1f0bf-db05-483c-a9ae-62b8e3ce8a83",
//     "secret": "179f7506-ca19-4794-bfcd-73db1f3bbe41",
//     "name": "Quang123",
//     "grants": [
//         "client_credentials"
//     ],
//     "scope": "read,write",
//     "user": {
//         "id": "e6d7c2dc-3111-4dfb-b060-3a12345e247e",
//         "name": "Quang123"
//     }
