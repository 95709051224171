import { DownOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import Button from 'components/Button';
import useQueryParam from 'hooks/useQueryPrams';

interface IProps {
  isShowEditButton?: boolean;
  isEditButtonEnable?: boolean;
  handleSearch: (values: any) => void;
  handleConfirmExport?: () => void;
}

export default function GIEditFilter(props: IProps): JSX.Element {
  const {
    isShowEditButton = false,
    isEditButtonEnable,
    handleSearch,
    handleConfirmExport,
  } = props;

  const onFinish = (values: any) => {
    const params = {
      ...values,
      fromDate: values.fromDate?._d.toLocaleDateString('fr-CA'),
      toDate: values.toDate?._d.toLocaleDateString('fr-CA'),
    };
    Object.keys(params).forEach((k) => params[k] == '' && delete params[k]);

    handleSearch(JSON.parse(JSON.stringify(params)));
  };
  const queryParam = useQueryParam();

  const search = queryParam.get('search') || undefined;
  const createdBy = queryParam.get('createdBy') || undefined;
  const plant = queryParam.get('plant') || undefined;
  const receivingPlant = queryParam.get('receivingPlant') || undefined;

  return (
    <div className="flex justify-between items-center mt-8 mb-10">
      <Form
        layout="inline"
        className="items-center"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="search">
          <Input placeholder="Mã GI" defaultValue={search} />
        </Form.Item>

        <Form.Item name="createdBy">
          <Input placeholder="Người tạo GI" defaultValue={createdBy} />
        </Form.Item>

        {/* <Form.Item name="status">
          <Select placeholder="Trạng thái">
            <Option value="abc">xyz</Option>
          </Select>
        </Form.Item> */}

        <Form.Item name="plant">
          <Input
            placeholder="Đơn vị xuất"
            suffix={<DownOutlined />}
            defaultValue={plant}
          />
        </Form.Item>

        <Form.Item name="receivingPlant">
          <Input
            placeholder="Đơn vị nhận"
            suffix={<DownOutlined />}
            defaultValue={receivingPlant}
          />
        </Form.Item>

        {/* <Form.Item>
          <Select placeholder="Người tạo GI">
            <Option value="abc">xyz</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Select placeholder="Người tạo GR">
            <Option value="abc">xyz</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Select placeholder="Shipper">
            <Option value="abc">xyz</Option>
          </Select>
        </Form.Item> */}

        {/* <Form.Item>
          <Select placeholder="Phương tiện">
            <Option value="abc">xyz</Option>
          </Select>
        </Form.Item> */}

        {/* <Form.Item name="fromDate">
          <DatePicker placeholder="Từ ngày" format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item name="toDate">
          <DatePicker placeholder="Đến ngày" format="DD/MM/YYYY" />
        </Form.Item> */}

        <Form.Item>
          <Button type="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>

      {isShowEditButton && (
        <Button
          borderRadius="md"
          disabled={!isEditButtonEnable}
          onClick={handleConfirmExport}
        >
          Điều chỉnh phiếu xuất
        </Button>
      )}
    </div>
  );
}
