import { Form, Select, Modal, Typography } from 'antd';
import RatingApi from 'apis/RatingApi';
import Button from 'components/Button';
import { IGetRatingQuery } from 'interface/rating.interface';
import { useMutation } from 'react-query';
import { ratingStatus } from 'utils/constants';
import { openNotificationWithIcon } from 'utils/notification';
import { renderObjectOptions } from 'utils/renderOptions';

interface IProps {
  isVisible?: boolean;
  rating?: IGetRatingQuery;
  onCancel: () => void;
}

export default function RatingUpdateGI(props: IProps): JSX.Element {
  const { rating, onCancel, isVisible } = props;

  const { mutate: rewaitingRating } = useMutation(
    RatingApi.putRewaitingRating,
    {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Cập nhật thành công');
      },
      onError: (error) => {
        console.log(error);
        openNotificationWithIcon('error', 'Cập nhật thất bại');
      },
    }
  );

  const handleRewaitingRating = async () => {
    if (!rating) return;
    rewaitingRating(rating?.id);
    onCancel();
  };

  return (
    <Modal
      centered
      visible={isVisible}
      footer={null}
      closable={false}
      onCancel={onCancel}
      width={300}
      className="rounded-2.5xl"
      wrapClassName="customModal"
    >
      <Typography>
        <pre>Mã GI: {rating?.gr_id}</pre>
      </Typography>

      <Form.Item name="status" style={{ width: '300' }}>
        <Select placeholder="Trạng thái">
          {renderObjectOptions(ratingStatus)}
        </Select>
      </Form.Item>

      <Form layout="inline" className="items-center" autoComplete="off">
        <Button size="sm" onClick={handleRewaitingRating}>
          Cập nhập
        </Button>
        <Button size="sm" onClick={onCancel}>
          Đóng
        </Button>
      </Form>
    </Modal>
  );
}
