/* eslint-disable react-hooks/exhaustive-deps */
import GiAPI from 'apis/GiAPI';
import { MainLayout } from 'components';
import GIFilter from 'components/Modules/GI/GIFilter';
import GITable from 'components/Modules/GI/GITable';
import useQueryParam from 'hooks/useQueryPrams';
import { IGiParams } from 'interface/gi.interface';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';
import queryString from 'query-string';

export default function GIManagementPage(): JSX.Element {
  const history = useHistory();
  const queryParam = useQueryParam();

  const page = parseInt(queryParam.get('page') + '') || 1;
  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;
  const plant = queryParam.get('plant') || undefined;
  const receivingPlant = queryParam.get('receivingPlant') || undefined;
  const search = queryParam.get('search') || undefined;
  const createdBy = queryParam.get('createdBy') || undefined;
  const status = queryParam.get('status') || undefined;
  const shipper = queryParam.get('shipper') || undefined;
  const grCreatedBy = queryParam.get('grCreatedBy') || undefined;
  const vehicleType = queryParam.get('vehicleType') || undefined;
  const toDate = queryParam.get('toDate') || undefined;
  const fromDate = queryParam.get('fromDate') || undefined;

  const giParams: Partial<IGiParams> = {
    page,
    page_size,
    plant,
    receivingPlant,
    search,
    createdBy,
    status,
    shipper,
    grCreatedBy,
    vehicleType,
    fromDate,
    toDate,
  };

  const params = JSON.parse(JSON.stringify(giParams));

  const { data: Gis = { data: [], total: 0 }, isLoading } = useQuery(
    ['adminGetGis', giParams],
    () => GiAPI.getGis(params)
  );

  const handleSearch = (values: any) => {
    const search: Partial<IGiParams> = JSON.parse(
      JSON.stringify({
        ...values,
        page: 1,
        page_size: page_size,
      })
    );

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(
        { ...search },
        { skipNull: true, skipEmptyString: true }
      ),
    });
  };

  return (
    <MainLayout>
      <p className="para-2 text-primary-0 text-opacity-45">Quản lý vận đơn</p>
      <h6 className="text-primary-30 mt-1">Danh sách vận đơn</h6>
      <GIFilter
        handleSearch={handleSearch}
        values={giParams}
        isLoading={isLoading}
      />

      <GITable
        dataSource={Gis?.data}
        hasSelection={false}
        page={page}
        pageSize={page_size}
        total={Gis?.total}
      />
    </MainLayout>
  );
}
