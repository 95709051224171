import { Table } from 'antd';
import { useHistory } from 'react-router';

interface IProps {
  dataSource: any[];
}

const columns = [
  { title: 'Ngày', dataIndex: 'date', key: 'date' },
  { title: 'Shipper', dataIndex: 'shipper', key: 'shipper' },
  { title: 'Tổng số vận đơn đã nhận', dataIndex: 'total', key: 'total' },
  {
    title: 'Tổng số vận đơn đã hoàn thành',
    dataIndex: 'finished',
    key: 'finished',
  },
  {
    title: 'Tổng số vận đơn chưa hoàn thành',
    dataIndex: 'unfinished',
    key: 'unfinished',
  },
];

export default function BillOfLadingTable(props: IProps): JSX.Element {
  const { dataSource } = props;
  const history = useHistory();

  return (
    <Table
      rowKey={(record) => record.id}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => {
            history.push(`/bill-of-lading/${rowIndex}`);
          },
        };
      }}
      rowClassName="cursor-pointer"
      dataSource={dataSource}
      columns={columns}
    />
  );
}
