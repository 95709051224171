import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { IJobTitle } from 'interface/permission.interface';
import { useEffect } from 'react';

interface IProps {
  isModalVisible: boolean;
  onCancel: () => void;
  mutationUser: (data: any) => void;
  currentJobTitle?: IJobTitle;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function UpdateJobTitleModal(props: IProps): JSX.Element {
  const { isModalVisible, onCancel, mutationUser, currentJobTitle } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [currentJobTitle]);

  return (
    <Modal
      title="Tạo mới Job Title"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        name="create_job_title"
        onFinish={mutationUser}
        initialValues={currentJobTitle}
        className="bg-white p-2 gap-4 lg:w-90 md:w-90 sm:w-100"
        {...formLayout}
      >
        <Form.Item label="Mã code" name="jobTitleCode">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Chức Vụ" name="jobTitle">
          <Input name="jobTitle" placeholder="Tư Vấn Viên" />
        </Form.Item>

        <Form.Item label="Mô tả" name="description">
          <Input
            type="text"
            name="description"
            placeholder="some description"
          />
        </Form.Item>

        <Form.Item label="Hoạt Động" name="active" valuePropName="checked">
          <Checkbox name="active" />
        </Form.Item>

        <Form.Item className="flex items-center justify-center">
          <Button type="primary" htmlType="submit" className="bg-primary">
            Tạo
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
