import {
  ITrackingShipperResponse,
  ITrackingShippersParams,
} from 'interface/tracking_shippers.interface';
import axiosClient from './axiosClient';
import qs from 'qs';

const path = 'abe/tracking';

const TrackingShipperApi = {
  getShippersLocation: (
    params: ITrackingShippersParams
  ): Promise<Array<ITrackingShipperResponse>> => {
    return axiosClient.get(`/${path}`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  },
};

export default TrackingShipperApi;
