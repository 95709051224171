/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import '@goongmaps/goong-js/dist/goong-js.css';
import ReactMapGL, {
  FlyToInterpolator,
  Marker,
} from '@goongmaps/goong-map-react';
import { LocationIcon } from 'assets/svg';
import { Button, Divider, Input } from 'antd';
import { IPlace, IPlant } from 'interface/plant.interface';
import { openNotificationWithIcon } from 'utils/notification';
import { debounce } from 'debounce';
import { useMutation } from 'react-query';
import PlantAPI from 'apis/PlantAPI';
import { useParams } from 'react-router';
import { useRef } from 'react';

export interface IViewPort {
  latitude: number;
  longitude: number;
  zoom: number;
  transitionDuration?: number;
  transitionInterpolator?: any;
}

const GOONG_MAPTILES_KEY =
  process.env.REACT_APP_GOONG_GOONG_MAPTILES_KEY ||
  '9S528bUz7h6zuHAp25qLxZjNpKC6BkubSPeDiLq0';

export default function PlantDetailPage(): JSX.Element {
  const [plantData, setPlantData] = useState<IPlant>();

  const [viewport, setViewport] = useState<IViewPort>({
    latitude: 10.7813073,
    longitude: 106.664661,
    zoom: 12,
  });

  const [placeList, setPlaceList] = useState<IPlace[]>([]);
  const [placeDetail, setPlaceDetail] = useState<any>();

  const overlay = useRef<boolean>(false);

  const [isOverlay, setOverlay] = useState<boolean>(overlay.current);

  const { code } = useParams<{ code: string }>();

  //fetch plant data
  const { mutate: getPlant } = useMutation(PlantAPI.getPlant, {
    onSuccess: (data) => {
      setPlantData(data);
      !data.latitude && !data.longitude && setOverlay(true);

      setViewport(() => {
        if (!data.longitude && !data.latitude) {
          return {
            ...viewport,
            latitude: viewport.latitude,
            longitude: viewport.longitude,
          };
        } else {
          return {
            ...viewport,
            latitude: +data.latitude,
            longitude: +data.longitude,
            zoom: 12,
          };
        }
      });
    },

    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    getPlant(code);
  }, [code, getPlant]);

  //update plant
  const { mutate: updatePlant } = useMutation(PlantAPI.updatePlant, {
    onSuccess: () => {
      openNotificationWithIcon('success', 'Cập nhật thành công');
    },
    onError: (error) => {
      console.log(error);
      openNotificationWithIcon('error', 'Cập nhật thất bại');
    },
  });

  //fetch places data
  const { mutate: getPlaces } = useMutation(PlantAPI.getPlaces, {
    onSuccess: (data) => {
      setPlaceList(data.data.predictions);
    },
    onError: (error) => {
      console.log(error);
      setPlaceList([]);
    },
  });

  //fecth place detail data
  const { mutate: getPlace } = useMutation(PlantAPI.getPlace, {
    onSuccess: (data) => {
      const place = data.data.result;

      setOverlay(true);

      setPlaceDetail(place);
      setPlaceList([]);

      const newLocation = {
        latitude: place.geometry.location.lat,
        longitude: place.geometry.location.lng,
      };

      plantData &&
        setPlantData({
          ...plantData,
          ...newLocation,
        });

      setViewport({
        ...viewport,
        ...newLocation,
        zoom: 14,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      });
    },
  });

  const handleUpdatePlant = async () => {
    if (!plantData) return;

    const payload = {
      code: plantData.code,
      longitude: +plantData.longitude,
      latitude: +plantData.latitude,
    };

    updatePlant(payload);
  };

  const handleSearchPlace = debounce((e: any) => {
    if (!e.target.value) {
      setPlaceList([]);
      return;
    }

    const location = `${plantData?.latitude},${plantData?.longitude}`;

    getPlaces({ location, input: e.target.value });
  }, 500);

  const handlePlaceClick = (p: IPlace) => {
    getPlace(p.place_id);
  };

  return (
    <>
      <div
        className="bg-neutral-50 opacity-80 w-full h-full top-0 left-0 right-0 bottom-0 fixed z-10"
        hidden={!isOverlay}
      >
        <div className="items-center flex flex-col flex-1 justify-center justify-items-center h-screen">
          <h6>Plant {plantData?.code} hiện chưa có vị trí chính xác</h6>
          <h6>
            Vui lòng cập nhật bằng cách nhập địa chỉ vào thanh tìm kiếm hoặc
          </h6>
          <h6>
            di chuyển icon {<LocationIcon className="inline-block	" />} trên bản
            đồ sau đó bấm nút cập nhật nhé.
          </h6>
          <Button
            className="mt-5"
            type="primary"
            onClick={() => setOverlay(false)}
          >
            Đã hiểu
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/4 px-2 py-4">
          <h6>{plantData?.name}</h6>
          <p>{plantData?.address}</p>
          <p>{plantData?.district}</p>
          <p>{plantData?.province}</p>

          <Divider />

          <div className="relative my-4">
            <Input onChange={handleSearchPlace} placeholder="Nhập địa chỉ" />
            {placeList?.length > 0 && (
              <div className="absolute left-0 right-0 top-5 p-2 z-10 bg-neutral-95 rounded-lg">
                {placeList.map((p) => (
                  <div
                    className="mb-2"
                    key={p.place_id}
                    onClick={() => handlePlaceClick(p)}
                  >
                    {p.description}
                  </div>
                ))}
              </div>
            )}
          </div>

          {placeDetail && (
            <div>
              <p>{placeDetail.name}</p>
              <p>{placeDetail.formatted_address}</p>
            </div>
          )}

          <Button type="primary" onClick={handleUpdatePlant}>
            Cập nhật
          </Button>
        </div>
        <ReactMapGL
          {...viewport}
          width="100%"
          height="100vh"
          onViewportChange={(nextViewport: IViewPort) =>
            setViewport(nextViewport)
          }
          goongApiAccessToken={GOONG_MAPTILES_KEY}
        >
          {plantData && (
            <Marker
              key={plantData.code}
              longitude={+plantData.longitude || viewport.longitude}
              latitude={+plantData.latitude || viewport.latitude}
              draggable={true}
              onDrag={(event: any) =>
                setPlantData({
                  ...plantData,
                  longitude: event.lngLat[0],
                  latitude: event.lngLat[1],
                })
              }
            >
              <LocationIcon />
            </Marker>
          )}
        </ReactMapGL>
      </div>
    </>
  );
}
