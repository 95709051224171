import { Form, Table, Modal, Image, Input } from 'antd';
import Button from 'components/Button';
import useQueryParam from 'hooks/useQueryPrams';
import { useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { TransportImage } from '../Vehicle';
import CommentInput from './CommentInput';

interface IProps {
  isVisible?: boolean;
  rating?: any;
  onCancel: () => void;
}

export default function RatingDetail(props: IProps): JSX.Element {
  const { rating, onCancel, isVisible } = props;
  console.log(rating);
  const queryParam = useQueryParam();
  const [editingKey, setEditingKey] = useState<string>('');
  const [currentFile, setCurrentFile] = useState<File>();
  const [visible, setVisible] = useState(false);

  const page = parseInt(queryParam.get('page') + '') || 1;

  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const isEditing = (record: any) => record?.id + '' === editingKey;

  const handleFileChange = (file: File) => {
    setCurrentFile(file);
  };

  const columns = [
    {
      title: 'Mã SP',
      dataIndex: 'material',
    },
    {
      title: 'Tên SP',
      dataIndex: 'product_name',
    },
    {
      title: 'Mã Batch',
      dataIndex: 'batch',
    },
    {
      title: 'Mã lỗi',
      dataIndex: 'error_code',
    },
    {
      title: 'Loại lỗi',
      dataIndex: 'error_name',
    },
    {
      title: 'Nguồn gốc SP',
      dataIndex: 'prod_origin_name',
    },
    {
      title: 'Ghi chú SP',
      dataIndex: 'note_item',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'urlImages',
      render: (urlImages: any, record: any) => {
        return (
          urlImages &&
          urlImages.length > 0 && (
            <>
              <Image
                preview={{ visible: false }}
                width={50}
                height={50}
                src={urlImages[0].url_image}
                onClick={() => setVisible(true)}
              />

              <div
                style={{
                  display: 'none',
                }}
              >
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                  }}
                >
                  {urlImages.map((image: any, index: number) => {
                    return <Image key={index} src={image.url_image} />;
                  })}
                </Image.PreviewGroup>
              </div>
            </>
          )
        );
      },
    },
    {
      title: 'Nhận xét PNJP',
      dataIndex: 'comment_pnjp',
      render: (comment_pnjp: any, record: any) => {
        return (
          <CommentInput
            key={record.id}
            rating={record}
            isComment={true}
          ></CommentInput>
        );
      },
    },
    {
      title: 'Ghi chú PNJP',
      dataIndex: 'note_pnjp',
      render: (comment_pnjp: any, record: any) => {
        return (
          <CommentInput
            key={record.id}
            rating={record}
            isComment={false}
          ></CommentInput>
        );
      },
    },
  ];

  return (
    <Modal
      centered
      visible={isVisible}
      footer={null}
      closable={false}
      onCancel={onCancel}
      width={969}
      className="rounded-2.5xl"
      wrapClassName="customModal"
    >
      <Table
        rowKey={(record, index) => record.id}
        columns={columns}
        dataSource={rating}
        className="customTable"
        size="small"
        scroll={{ x: 1500 }}
        pagination={{
          current: page,
          pageSize: page_size,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
        }}
      />
      <div className="flex flex-row-reverse">
        <Form layout="inline" className="items-center" autoComplete="off">
          <Button size="sm" onClick={onCancel}>
            Đóng
          </Button>
        </Form>
      </div>
    </Modal>
  );
}
