import { Table } from 'antd';
import { IPagingResponse } from 'interface/base.interface';
import { IRoute } from 'interface/route.interface';
import { useHistory } from 'react-router-dom';
import { HocChangePagination } from 'utils/HocChangePagination';

interface IProps {
  dataSource?: IPagingResponse<IRoute>;
  page: number;
  page_size: number;
  loading?: boolean;
  handleRowClick: (data: any) => void;
}

const columns = [
  {
    title: 'Mã công ty',
    dataIndex: 'orgCode',
    key: 'orgCode',
  },
  {
    title: 'Mã khu vực (mã tuyến)',
    dataIndex: 'routeCode',
    key: 'routeCode',
  },
  {
    title: 'Tên khu vực',
    dataIndex: 'routeName',
    key: 'routeName',
  },
  {
    title: 'Mã khu vực (theo SAP)',
    dataIndex: 'routeCodeSap',
    key: 'routeCodeSap',
  },
  {
    title: 'Tên khu vực (theo SAP)',
    dataIndex: 'routeCodeNameSap',
    key: 'routeCodeNameSap',
  },
  {
    title: 'Mã chi nhánh',
    dataIndex: 'departmentCode',
    key: 'departmentCode',
  },
  {
    title: 'Tên chi nhánh',
    dataIndex: 'departmentName',
    key: 'departmentName',
  },
  {
    title: 'Mã cửa hàng',
    dataIndex: 'plantCode',
    key: 'plantCode',
  },
  {
    title: 'Tên cửa hàng',
    dataIndex: 'plantName',
    key: 'plantName',
  },
];

export default function RouteTable(props: IProps): JSX.Element {
  const {
    dataSource,
    page,
    page_size,
    loading = false,
    handleRowClick,
  } = props;

  // const page = parseInt(getParam('page', 'page') + '');
  // const page_size = parseInt(getParam('page_size', 'page_size') + '');

  const history = useHistory();

  return (
    <Table
      className="customTable"
      dataSource={dataSource?.data}
      columns={columns}
      loading={loading}
      onRow={(record: IRoute) => {
        return {
          onClick: () => {
            handleRowClick(record);
          },
        };
      }}
      pagination={{
        current: page,
        pageSize: page_size,
        total: dataSource?.total,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        onChange: HocChangePagination(history),
      }}
    />
  );
}
