/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Table } from 'antd';
import OauthClientAPI from 'apis/OauthClientAPI';
import UpdateClientModal from 'components/Modules/Clients/UpdateClientModal';
import MainLayout from 'components/Modules/Layout/MainLayout';
import { OauthClient } from 'interface/oauth_client';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { openNotificationWithIcon } from 'utils/notification';

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Secret key',
    dataIndex: 'secret',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Grants',
    dataIndex: 'grants',
  },
  {
    title: 'Scope',
    dataIndex: 'scope',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
  },
  {
    title: 'Created by',
    dataIndex: 'createdBy',
  },
];

function ClientsManagementPage(): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const history = useHistory();
  const [currentClient, setCurrentClient] = useState<OauthClient>();

  const { data: clients, refetch: refetchClients } = useQuery<OauthClient[]>(
    'getClients',
    OauthClientAPI.getClients
  );

  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    console.log('params', pagination, filters, sorter, extra);
  }

  // const { mutate: getClient, data: client } = useMutation(
  //   OauthClientAPI.getClient,
  //   {
  //     onSuccess: () => {
  //       setIsVisible(true);
  //     },
  //     onError: (error: any) => {
  //       error.response.data.message.map((err: any) =>
  //         openNotificationWithIcon('error', err)
  //       );
  //     },
  //   }
  // );

  // console.log({ client });

  const { mutate: updateClientMutate } = useMutation(
    OauthClientAPI.editClient,
    {
      onSuccess: () => {
        openNotificationWithIcon('success', 'Cập nhật thành công!');
        refetchClients();
        setIsVisible(false);
      },
      onError: () => {
        openNotificationWithIcon('error', 'Cập nhật không thành công!');
      },
    }
  );

  const onsubmit = (values: any) => {
    if (!currentClient) return;
    updateClientMutate({ ...values, id: currentClient.id });
  };

  const handleOnRowClick = (record: OauthClient) => {
    setCurrentClient(record);
    setIsVisible(true);
  };

  return (
    <MainLayout>
      <Button onClick={() => history.push('/clients-management/new-client')}>
        Tạo Client
      </Button>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={clients}
        onChange={onChange}
        pagination={false}
        onRow={(record: OauthClient) => {
          return {
            onClick: () => handleOnRowClick(record),
          };
        }}
      />
      <UpdateClientModal
        isModalVisible={isVisible}
        onCancel={() => setIsVisible(false)}
        client={currentClient}
        submit={onsubmit}
      />
    </MainLayout>
  );
}

export default ClientsManagementPage;
