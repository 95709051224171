import { Input, Form } from 'antd';
import RouteApi from 'apis/RouteApi';
import { MagifyingGlassIcon } from 'assets/svg';
import { MainLayout } from 'components';
import Button from 'components/Button';
import RouteTable from 'components/Modules/Route/RouteTable';
import ScheduleTable from 'components/Modules/Route/ScheduleTable';
import { useUser } from 'contexts/User';
import useQueryParam from 'hooks/useQueryPrams';
import { useHistory } from 'react-router-dom';
import updateQueryStringParameter from 'utils/updateQueryStringParameter';
import {
  IGetRoutesQuery,
  IRoute,
  IUpdateRoute,
} from 'interface/route.interface';
import { openNotificationWithIcon } from 'utils/notification';
import handleError401 from 'utils/handleError401';
import { useMutation, useQuery } from 'react-query';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { UploadOutlined } from '@ant-design/icons';
import RouteModal from 'components/Modules/Route/RouteModal';
import { useState } from 'react';

export default function RouteManagementPage(): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [currentRoute, setCurrentRoute] = useState<IRoute>();
  const { user } = useUser();

  const queryParam = useQueryParam();

  const page = parseInt(queryParam.get('page') + '') || 1;

  const page_size =
    parseInt(queryParam.get('page_size') + '') || DEFAULT_PAGE_SIZE;

  const search = queryParam.get('search') || undefined;

  const routeCode = queryParam.get('routeCode') || undefined;

  const routeName = queryParam.get('routeName') || undefined;

  const departmentCode = queryParam.get('departmentCode') || undefined;

  const plantName = queryParam.get('plantName') || undefined;

  const history = useHistory();

  const routeParams = {
    page,
    page_size,
    search,
    routeCode,
    routeName,
    departmentCode,
    plantName,
  };

  const params = JSON.parse(JSON.stringify(routeParams));

  const {
    data: routes,
    refetch: refetchRoutes,
    isLoading: getRoutesLoading,
  } = useQuery(['getRoutes', routeParams], () => RouteApi.getRoutes(params));

  const { mutate: uploadRoute, isLoading: uploadFileLoading } = useMutation(
    RouteApi.uploadRoute,
    {
      onSuccess: () => {
        refetchRoutes();
        openNotificationWithIcon('success', 'Upload file thành công');
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', 'Upload file thất bại');
        if (error.response.data.status === 401) {
          handleError401();
        }
      },
    }
  );

  const { mutate: updateRoute, isLoading: updateRouteLoaing } = useMutation(
    RouteApi.updateRoute,
    {
      onSuccess: () => {
        refetchRoutes();
        openNotificationWithIcon('success', 'Cập nhật tuyến đường thành công');
        setIsVisible(false);
      },
      onError: (error: any) => {
        openNotificationWithIcon('error', 'Cập nhật tuyến đường thất bại');
      },
    }
  );

  const onFinish = (values: any) => {
    const routeParams: Partial<IGetRoutesQuery> = JSON.parse(
      JSON.stringify({
        ...values,
        fromDate: values.fromDate?._d.toLocaleDateString('fr-CA'),
        toDate: values.toDate?._d.toLocaleDateString('fr-CA'),
        page: 1,
        page_size,
      })
    );

    history.push({
      pathname: location.pathname,
      search: updateQueryStringParameter(location.search, routeParams),
    });
  };

  const handleUploadFile = (e: any) => {
    const [file] = e.target.files;
    if (!file) {
      e.target.value = '';
      return;
    }

    if (
      !file?.type?.match(
        /vnd.ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet/
      ) &&
      !file?.name?.match(/xls/)
    ) {
      openNotificationWithIcon(
        'error',
        'Chỉ chấp nhận file có đuôi .xls hoặc .xlsx'
      );
      e.target.value = '';
      return;
    }

    const data = new FormData();
    data.append('file', file);

    uploadRoute(data);
    e.target.value = '';
  };

  const handleRowClick = (data: any) => {
    setCurrentRoute(data);
    setIsVisible(true);
  };

  const handleUpdateRoute = (data: IRoute) => {
    console.log(data);
    const { routeCode, routeName, routeCodeSap, routeCodeNameSap } = data;
    if (!currentRoute) return;
    if (!routeCode || !routeName || !routeCodeSap || !routeCodeNameSap)
      return openNotificationWithIcon(
        'error',
        'Phải có ít nhất 1 cột có giá trị'
      );

    const payload: IUpdateRoute = {
      routeId: currentRoute.routeId,
      routeCode,
      routeName,
      routeCodeSap,
      routeCodeNameSap,
    };

    updateRoute(payload);
  };

  return (
    <MainLayout isLoading={uploadFileLoading}>
      <p className="mb-1 para-2 text-primary-0 opacity-45">
        Xin chào {user?.fullName}
      </p>

      <h6 className="mb-8 ">Quản lý tuyến đường</h6>

      <Form layout="inline" className="items-center" onFinish={onFinish}>
        <Form.Item name="search">
          <Input
            placeholder="Tìm kiếm"
            suffix={<MagifyingGlassIcon />}
            defaultValue={search}
          />
        </Form.Item>

        <Form.Item name="routeCode">
          <Input placeholder="Mã khu vực" defaultValue={routeCode} />
        </Form.Item>

        <Form.Item name="routeName">
          <Input placeholder="Tên khu vực" defaultValue={routeName} />
        </Form.Item>

        <Form.Item name="departmentCode">
          <Input placeholder="Mã chi nhánh" defaultValue={departmentCode} />
        </Form.Item>

        <Form.Item name="plantName">
          <Input placeholder="Tên cửa hàng" defaultValue={plantName} />
        </Form.Item>

        <Form.Item>
          <Button type="submit">Tìm kiếm</Button>
        </Form.Item>
      </Form>
      <div className="flex justify-between mt-8 mb-2">
        <h6 className="text-xl font-bold">Danh sách tuyến đường</h6>
      </div>
      <div className="flex flex-row-reverse">
        <label
          htmlFor="upload-file"
          className="cursor-pointer flex items-center justify-evenly px-2 py-1 border rounded-full text-primary-50 border-primary-50"
        >
          <UploadOutlined />

          <span className="ml-2 para-2">Upload File</span>
        </label>

        <input
          type="file"
          onChange={handleUploadFile}
          accept=".xlsx,.xls"
          id="upload-file"
          className="hidden"
        />
      </div>
      <RouteTable
        dataSource={routes}
        page={page}
        page_size={page_size}
        loading={getRoutesLoading}
        handleRowClick={handleRowClick}
      />

      <div className="flex justify-between mt-8 mb-2">
        <h6 className="text-xl font-bold">
          Danh sách giao nhận theo tuyến đường
        </h6>
      </div>
      <ScheduleTable />
      <RouteModal
        isModalVisible={isVisible}
        onCancel={() => setIsVisible(false)}
        handleUpdateRoute={handleUpdateRoute}
        currentRoute={currentRoute}
      />
    </MainLayout>
  );
}
