import { IPagingResponse } from 'interface/base.interface';
import {
  IGetRoutesQuery,
  IRoute,
  ISchedule,
  IUpdateRoute,
  IUploadSchedulesResponse,
} from 'interface/route.interface';
import axiosClient from './axiosClient';

const path = 'abe/route';

const RouteApi = {
  getRoutes: (params?: IGetRoutesQuery): Promise<IPagingResponse<IRoute>> => {
    return axiosClient.get(`/${path}`, { params });
  },

  getSchedules: (params: {
    month: number;
    year: number;
  }): Promise<ISchedule[]> => {
    return axiosClient.get(`/${path}/schedules`, { params });
  },

  uploadRoute: (data: FormData) => {
    return axiosClient.post(`${path}/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  uploadSchedules: (
    year: number,
    file: File
  ): Promise<IUploadSchedulesResponse> => {
    const formData = new FormData();
    formData.append('file', file);

    return axiosClient.post(`${path}/uploadSchedule?year=${year}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updateRoute: ({ routeId, ...data }: IUpdateRoute) => {
    return axiosClient.put(`${path}/${routeId}`, data);
  },
};

export default RouteApi;
