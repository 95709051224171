import { IPagingResponse, IResponse } from 'interface/base.interface';
import {
  IGi,
  IGiDetail,
  IGiDetailInfo,
  IGiParams,
} from 'interface/gi.interface';
import axiosClient from './axiosClient';
const path = 'abe/gi';

const GiAPI = {
  getGis: (params: IGiParams): Promise<IPagingResponse<IGi>> => {
    return axiosClient.get(`/${path}`, { params });
  },
  getGiDetail: (params: IGiParams): Promise<IPagingResponse<IGiDetailInfo>> => {
    return axiosClient.get(`/${path}/detail`, { params });
  },

  holdGi: (documents: string[]): Promise<IResponse> => {
    return axiosClient.post(`/${path}/hold`, { documents });
  },

  getGisToHold: (params: IGiParams): Promise<IPagingResponse<IGi>> => {
    return axiosClient.get(`/${path}/hold`, { params });
  },

  getGIDT: (document: string): Promise<IGiDetail> => {
    return axiosClient.get(`/${path}/detail/${document}`);
  },
};

export default GiAPI;
