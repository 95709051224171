/* eslint-disable @typescript-eslint/no-explicit-any */
import oauth_clients from 'apis/OauthClientAPI';
import ClientForm from 'components/Modules/Clients/ClientForm';
import MainLayout from 'components/Modules/Layout/MainLayout';
import { CreateOauthClient } from 'interface/oauth_client';
import { useHistory } from 'react-router-dom';
import { openNotificationWithIcon } from 'utils/notification';

function NewClientPage(): JSX.Element {
  const history = useHistory();
  const onSubmit = async (values: CreateOauthClient) => {
    try {
      await oauth_clients.newClient(values);
      openNotificationWithIcon('success', 'Tạo client thành công!');
      history.push('/clients-management');
    } catch (error: any) {
      error.response.data.message.map((err: any) =>
        openNotificationWithIcon('error', err)
      );
    }
  };
  return (
    <MainLayout>
      <ClientForm submit={onSubmit} />
    </MainLayout>
  );
}

export default NewClientPage;
